#relationProliste,
#consulteventepro{
    width: 100%;
}
.form.newform {
    .block {
        width: 95%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        .btn.formbtn {
            border: 2px solid black;
            border-radius: 10px;
            padding: 10px 25px;
            width: 90%;
            text-align: center;
            margin-bottom: 35px;
            background: none;
            transition: 500ms;
            cursor: pointer;
            &:hover {
                border-color: #46ce85;
            }
            &.selected {
                background: #46ce85;
                border-color: #33ad6c;
                color: white;
            }
        }
    }
}
@media only screen and (min-width: 1280px) {
    .form.newform {
        margin-top: 35px;
        .block {
            flex-direction: row;
            .btn.formbtn {
                width: 350px;
                &.selected {
                }
            }
        }
    }
}