@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap');
@import "variableFront";
@import "front/reset";
@import "front/login";
@import "front/header";
@import "front/blockenter";
@import "front/form";
@import "front/footer";
@import "front/particulierProjet";
@import "front/proRelations";
@import "front/projetCree";
@import "front/seeProfilPro";
@import "front/abo";
@import "front/page404";
@import "front/debug";
@import "front/chatParticulier";
@import "front/chatPro";
@import "front/alertePro";
@import "front/blockSearch";
@import "front/newform";
@import "front/blockChoix";
@import "front/formcontactinde";