.footerBotton {
    position: relative;
    height: 100%;
    #vcard{
        position: fixed;
        bottom: 20px;
        left: 20px;
        cursor: pointer;
        width: 41px;
        height: 32px;
        color: rgba(#000000,0);
        background: url("../img/vcard.svg") center center no-repeat;
    }
    #app {
        height: auto;
    }
    .profilpro{
        #btn2 a{
                font-size: 11px;

            }

    }
    .blockcontent.seeProfilPro {
        .blockReseau {
            .logo {
                div{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 300px;
                    margin: 50px auto 0;
                    div{
                        margin-left: 10px;
                        &:first-child{
                            margin-left: 0;
                        }
                    }
                }
            }
            .linkretour {
                text-align: center;
                margin-bottom: 45px;

                a {
                    text-decoration: none;
                    background: #43A1DB;
                    color: white;
                    font-size: 18px;
                    font-weight: bold;
                    border-style: none;
                    border-radius: 15px;
                    padding: 12px 30px;
                    transition: 500ms;
                    box-shadow: rgb(0 0 0 / 32%) 0px 3px 12px;

                    &:hover {
                        background: #3186ba;
                        color: white;
                    }
                }
            }
        }

        .profilpro {
            margin-top: 100px;

            .linkretour {
                text-align: center;
                margin-bottom: 45px;

                a {
                    text-decoration: none;
                    background: #43A1DB;
                    color: white;
                    font-size: 16px;
                    font-weight: bold;
                    border-style: none;
                    border-radius: 15px;
                    padding: 12px 30px;
                    transition: 500ms;
                    box-shadow: rgb(0 0 0 / 32%) 0px 3px 12px;

                    &:hover {
                        background: #3186ba;
                        color: white;
                    }
                }
            }

            .block-1 {
                width: 100%;
                margin: auto;

                .containerBlock {

                    flex-wrap: wrap;
                }

                .blockImgVideoPro {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    align-items: center;

                    .profil {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        p {
                            font-size: 20px;
                            color: #2F2F2F;
                            margin-bottom: 15px;

                            &:nth-child(2) {
                                font-size: 28px;

                            }
                        }

                        a {
                            font-size: 18px;
                            color: #939393;
                            margin-bottom: 35px;
                            transition: 500ms;

                            &:hover {
                                color: #2F2F2F;
                            }
                        }
                    }

                    img {
                        width: 190px;
                        height: 190px;
                        border-radius: 100%;
                        border: 4px solid #939393;
                        margin-bottom: 25px;
                    }

                }

                .link_logo_action {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 300px;
                    margin: 50px auto 35px;

                    .newIcon {
                        margin-left: 40px;
                        &:first-child{
                            margin-left: 0;
                        }
                    }
                }

                .containerBlock {
                    margin-top: 50px;

                    .avisClient_block {
                        width: 250px;
                        margin: 0 auto 75px;

                        a {
                            display: block;
                            font-size: 28px;
                            color: #2F2F2F;
                            font-weight: 600;
                        }
                    }
                }

                .blockvideo {
                    max-width: 300px;
                }

                .blockInfoPro {
                    width: 440px;
                    margin-top: 50px;

                    h3 {
                        font-size: 24px;
                        font-weight: 600;
                        color: #2f2f2f;
                        margin: 20px 0;
                        text-align: left;
                    }

                    .num {

                        margin-bottom: 10px;

                        p {
                            color: #2F2F2F;
                            font-size: 16px;
                            font-weight: 600;
                            position: relative;
                            text-align: left;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;

                            svg {
                                margin-left: 5px;
                                margin-right: 5px;
                            }
                        }
                    }

                    .mail {
                        margin-bottom: 10px;

                        p {
                            font-size: 16px;
                            text-align: left;
                            font-weight: 600;

                            a {
                                color: #2F2F2F;
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;

                                &:hover {
                                    color: #939393;
                                }

                                svg {
                                    margin-left: 5px;
                                    margin-right: 5px;
                                }
                            }
                        }
                    }

                    .fonction {
                        margin-bottom: 10px;

                        h2 {
                            color: #2F2F2F;
                            font-size: 16px;
                            font-weight: 600;
                        }

                        p {
                            text-align: center;
                            color: #939393;
                            font-size: 16px;
                            font-weight: 400;
                            margin-left: 10px;
                        }
                    }

                }

                .avisclient {
                    width: 100%;
                    margin: 0 auto;
                    text-align: center;

                }

                .blockAgence {
                    width: 100%;
                    margin-top: 50px;

                    h3 {
                        text-align: left;
                        font-size: 24px;
                    }

                    .social {
                        margin-bottom: 10px;

                        h2 {
                            color: #939393;
                            font-size: 16px;
                            font-weight: 400;
                            text-align: left;
                        }

                        p {
                            color: #2F2F2F;
                            font-size: 16px;
                            font-weight: 600;
                            text-align: left;
                        }
                    }

                    .agency {
                        margin-bottom: 10px;

                        h2 {
                            font-size: 16px;
                            color: #939393;
                            font-weight: 400;
                            text-align: left;
                        }

                        p {
                            text-align: left;
                            font-weight: 600;
                            color: #2F2F2F;
                            font-size: 16px;
                        }
                    }

                    .city {
                        margin-bottom: 10px;

                        h2 {
                            color: #2F2F2F;
                            font-size: 16px;
                            font-weight: 600;
                        }

                        p {
                            text-align: center;
                            color: #939393;
                            font-size: 16px;
                            font-weight: 400;
                            margin-left: 10px;
                        }
                    }

                    .website {
                        margin-bottom: 10px;

                        h2 {
                            font-size: 16px;
                            font-weight: 400;
                            color: #939393;
                            text-align: left;
                        }

                        a {
                            display: block;
                            text-align: left;
                            color: #2F2F2F;
                            font-size: 16px;
                            font-weight: 600;
                            width: 100%;
                        }
                    }
                }

                .blockReseau {
                    width: 100%;
                    margin-top: 50px;

                    h3 {
                        text-align: left;
                        font-size: 24px;
                        font-weight: 600;
                        color: #2f2f2f;
                        margin: 20px 0;
                    }

                    .logo {
                        div {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                        }
                    }

                    .facebook {
                        margin-bottom: 10px;

                        h2 {
                            color: #2F2F2F;
                            font-size: 16px;
                            font-weight: 600;
                            text-align: end;
                            width: 114px;
                            margin: 0;
                        }

                        svg {
                            margin-left: 10px;
                        }
                    }

                    .insta {
                        margin-bottom: 10px;

                        h2 {
                            color: #2F2F2F;
                            font-size: 16px;
                            font-weight: 600;
                            text-align: end;
                            width: 114px;
                            margin: 0;
                        }

                        svg {
                            margin-left: 10px;
                        }
                    }

                    .twitter {
                        margin-bottom: 10px;

                        h2 {
                            color: #2F2F2F;
                            font-size: 16px;
                            font-weight: 600;
                            text-align: end;
                            width: 114px;
                            margin: 0;
                        }

                        svg {
                            margin-left: 10px;
                        }
                    }

                    .linkedln {
                        margin-bottom: 10px;

                        h2 {
                            color: #2F2F2F;
                            font-size: 16px;
                            font-weight: 600;
                            text-align: end;
                            width: 114px;
                            margin: 0;
                        }

                        svg {
                            margin-left: 10px;
                        }
                    }

                    .newIcon {
                        position: relative;
                        top: -4px;
                        margin-left: 11px;
                    }
                }

                .blockPresentation {
                    margin: 50px 0;

                    h2 {
                        color: #2F2F2F;
                        font-size: 24px;
                        text-align: left;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        span{
                            font-size: 30px;
                            font-weight: 700;
                            cursor: pointer;
                        }
                    }
                    p{
                        display: none;
                        opacity: 0;
                        transition: opacity 500ms;

                    }
                }

                .avisclient {
                    a {
                        color: #939393;
                        font-size: 16px;
                        font-weight: 400;
                    }
                }
            }

            .blockPerimetre {
                margin: 10px auto 50px;

                h2 {
                    color: #2F2F2F;
                    font-size: 24px;
                    text-align: left;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    span{
                        font-size: 30px;
                        font-weight: 700;
                        cursor: pointer;
                    }
                }

                .perimetre {
                    display: none;
                    opacity: 0;
                    transition: opacity 500ms;
                    .block {
                        width: 261px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border: 2px solid #2f2f2f;
                        border-radius: 15px;
                        height: 66px;
                        margin: 10px 0;
                    }

                    p {
                        text-align: left;
                    }
                }
            }

            .blockService {
                width: 100%;
                margin: 0 auto 100px;

                h2 {
                    color: #2F2F2F;
                    font-size: 24px;
                    font-weight: 400;
                    margin-bottom: 30px;
                    text-align: left;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    span{
                        font-size: 30px;
                        font-weight: 700;
                        cursor: pointer;
                    }
                }

                .blockFull {
                    display: none;
                    opacity: 0;
                    transition: opacity 500ms;
                    .block {
                        width: 100%;

                        h3 {
                            color: #2F2F2F;
                            font-size: 20px;
                            font-weight: 600;
                            text-align: left;
                        }

                        .vente {
                            margin-top: 10px;
                            width: 95%;
                        }

                        .chasse {
                            margin-top: 10px;
                            width: 95%;
                        }

                        .relation {
                            margin-top: 10px;
                            width: 95%;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 375px) {
    .footerBotton {
        .blockcontent.seeProfilPro {
            .profilpro {
                .linkretour {
                    a {
                        &:hover {
                        }
                    }
                }

                .block-1 {
                    .blockImgVideoPro {
                        img {
                        }

                        p {
                        }
                    }

                    .blockvideo {
                    }

                    .blockInfoPro {
                        width: 290px;
                        margin: 0 auto;

                        h3 {
                        }

                        .num {
                            h2 {
                            }

                            p {
                            }
                        }

                        .mail {
                            h2 {
                            }

                            p {
                            }
                        }

                        .fonction {
                            h2 {
                            }

                            p {
                            }
                        }
                    }

                    .blockAgence {
                        margin: 0 auto;

                        .social {
                            h2 {
                            }

                            p {
                            }
                        }

                        .agency {
                            h2 {
                            }

                            p {
                            }
                        }

                        .city {
                            h2 {
                            }

                            p {
                            }
                        }

                        .website {
                            h2 {
                            }

                            p {
                            }
                        }
                    }

                    .blockReseau {
                        margin: 0 auto;

                        .facebook {
                            h2 {
                            }

                            svg {
                            }
                        }

                        .insta {
                            h2 {
                            }

                            svg {
                            }
                        }

                        .twitter {
                            h2 {
                            }

                            svg {
                            }
                        }

                        .linkedln {
                            h2 {
                            }

                            svg {
                            }
                        }
                    }
                }

                .blockPerimetre {
                    h2 {
                    }

                    .perimetre {
                        .block {
                        }
                    }
                }

                .blockService {
                    h2 {
                    }

                    .blockFull {
                        .block {
                            h3 {
                            }

                            .vente {
                            }

                            .chasse {
                            }

                            .relation {
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 600px) {
    .footerBotton {
        .blockcontent.seeProfilPro {
            .profilpro{
                #btn2 a{
                    font-size: 18px;

                }

            }
            .profilpro {
                .linkretour {
                    a {
                        &:hover {
                        }
                    }
                }

                .block-1 {
                    .blockImgVideoPro {
                        img {
                        }

                        p {
                        }

                        video {
                            height: auto;
                            width: 510px;
                        }
                    }

                    .containerBlock {
                        margin-top: 50px;
                    }

                    .blockInfoPro {
                        width: 100%;

                        h3 {
                        }

                        .num {
                            h2 {
                            }

                            p {
                            }
                        }

                        .mail {
                            h2 {

                            }

                            p {

                            }
                        }

                        .fonction {
                            h2 {
                            }

                            p {
                            }
                        }
                    }

                    .blockAgence {
                        width: 100%;

                        .social {
                            h2 {
                            }

                            p {
                            }
                        }

                        .agency {
                            h2 {
                            }

                            p {
                            }
                        }

                        .city {
                            h2 {
                            }

                            p {
                            }
                        }

                        .website {
                            h2 {
                            }

                            p {
                            }
                        }
                    }

                    .blockReseau {
                        width: 100%;

                        .facebook {
                            h2 {
                            }

                            svg {
                            }
                        }

                        .insta {
                            h2 {
                            }

                            svg {
                            }
                        }

                        .twitter {
                            h2 {
                            }

                            svg {
                            }
                        }

                        .linkedln {
                            h2 {
                            }

                            svg {
                            }
                        }
                    }
                }

                .blockPerimetre {
                    width: 95%;

                    h2 {
                    }

                    .perimetre {
                        .block {
                        }
                    }
                }

                .blockService {
                    h2 {
                    }

                    .blockFull {
                        .block {
                            h3 {
                            }

                            .vente {
                            }

                            .chasse {
                            }

                            .relation {
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 800px) {
    .footerBotton {
        .blockcontent.seeProfilPro {
            .profilpro {
                .linkretour {
                    a {
                        &:hover {
                        }
                    }
                }

                .block-1 {
                    .blockImgVideoPro {
                        img {
                        }

                        p {
                        }

                        video {
                        }
                    }

                    .blockInfoPro {
                        h3 {
                        }

                        .num {
                            h2 {
                            }

                            p {
                            }
                        }

                        .mail {
                            h2 {
                            }

                            p {
                            }
                        }

                        .fonction {
                            h2 {
                            }

                            p {
                            }
                        }
                    }

                    .blockAgence {
                        .social {
                            h2 {
                            }

                            p {
                            }
                        }

                        .agency {
                            h2 {
                            }

                            p {
                            }
                        }

                        .city {
                            h2 {
                            }

                            p {
                            }
                        }

                        .website {
                            h2 {
                            }

                            p {
                            }
                        }
                    }

                    .blockReseau {
                        .facebook {
                            h2 {
                            }

                            svg {
                            }
                        }

                        .insta {
                            h2 {
                            }

                            svg {
                            }
                        }

                        .twitter {
                            h2 {
                            }

                            svg {
                            }
                        }

                        .linkedln {
                            h2 {
                            }

                            svg {
                            }
                        }
                    }
                }

                .blockPerimetre {
                    h2 {
                    }

                    .perimetre {
                        .block {
                        }
                    }
                }

                .blockService {
                    .blockFull {
                        .block {
                            .vente {
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 960px) {
    .footerBotton {
        .blockcontent.seeProfilPro {
            .profilpro {
                .linkretour {
                    a {
                        &:hover {
                        }
                    }
                }

                .block-1 {
                    .blockImgVideoPro {
                        img {
                        }

                        p {
                        }

                        video {
                        }
                    }

                    .containerBlock {
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-start;
                        flex-wrap: wrap;

                        .blockAgence {
                            width: 50% !important;
                            margin: 0;

                            .website,
                            .agency,
                            .social {
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;

                                h2 {
                                    margin-bottom: 0;
                                }

                                a {
                                    width: auto;
                                    margin-left: 5px;
                                }
                            }
                        }

                        .blockReseau {
                            width: 30%;
                            margin: 0;
                        }

                        .blockPresentation {
                            width: 100%;
                            margin: 40px 0;
                        }
                    }

                    .blockInfoPro {
                        h3 {
                        }

                        .num {
                            h2 {
                            }

                            p {
                            }
                        }

                        .mail {
                            h2 {
                            }

                            p {
                            }
                        }

                        .fonction {
                            h2 {
                            }

                            p {
                            }
                        }
                    }

                    .blockAgence {
                        .social {
                            h2 {
                            }

                            p {
                            }
                        }

                        .agency {
                            h2 {
                            }

                            p {
                            }
                        }

                        .city {
                            h2 {
                            }

                            p {
                            }
                        }

                        .website {
                            h2 {
                            }

                            p {
                            }
                        }
                    }

                    .blockReseau {
                        .facebook {
                            h2 {
                            }

                            svg {
                            }
                        }

                        .insta {
                            h2 {
                            }

                            svg {
                            }
                        }

                        .twitter {
                            h2 {
                            }

                            svg {
                            }
                        }

                        .linkedln {
                            h2 {
                            }

                            svg {
                            }
                        }
                    }
                }

                .blockPerimetre {
                    h2 {
                    }

                    .perimetre {
                        .block {
                        }
                    }
                }

                .blockService {
                    .blockFull {
                        .block {
                            .vente {
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 1280px) {
    .footerBotton {
        .blockcontent.seeProfilPro {
            display: block;

            .profilpro {
                .linkretour {
                    a {
                        &:hover {
                        }
                    }
                }

                .block-1 {
                    .blockImgVideoPro {

                        .profil {
                            img {
                                width: auto;
                                height: 200px;
                            }

                            p {
                            }
                        }

                        .video {
                            video {
                                margin-top: 25px;
                                height: 361px;
                                width: 642px;
                            }
                        }
                    }

                    .containerBlock {
                        display: flex;
                        margin-top: 25px;

                        .blockInfoPro {
                            width: 35% !important;

                            h3 {
                            }

                            .num {
                                display: flex;
                                justify-content: flex-start;

                                h2 {
                                    text-align: start;
                                    width: auto;
                                }

                                p {
                                    text-align: start;
                                }
                            }

                            .mail {
                                display: flex;
                                justify-content: flex-start;
                                margin-bottom: 0;

                                h2 {
                                    text-align: start;
                                    width: auto;
                                }

                                p {
                                    text-align: start;
                                }
                            }

                            .fonction {
                                display: flex;
                                justify-content: flex-start;

                                h2 {
                                    text-align: start;
                                    width: auto;
                                }

                                p {
                                    text-align: start;
                                }
                            }
                        }

                        .blockAgence {
                            .social {
                                display: flex;
                                margin-bottom: 0;

                                h2 {
                                    text-align: left;
                                    width: auto;
                                }

                                p {
                                }
                            }

                            .agency {
                                display: flex;
                                margin-bottom: 0;

                                h2 {
                                    text-align: left;
                                    width: auto;
                                }

                                p {
                                }
                            }

                            .city {
                                display: flex;
                                margin-bottom: 0;

                                h2 {
                                    text-align: left;
                                    width: auto;

                                }

                                p {
                                }
                            }

                            .website {
                                display: flex;
                                margin-bottom: 0;

                                h2 {
                                    text-align: left;
                                    width: 48px;
                                }

                                a {
                                    text-align: left;
                                }

                                p {
                                }
                            }
                        }

                        .blockReseau {
                            width: 35% !important;

                            h3 {
                                width: 100%;

                            }

                            .logo {
                                div {
                                    display: flex;
                                    justify-content: flex-start;
                                    align-items: center;
                                    flex-wrap: wrap;
                                }
                            }

                            .facebook {
                                h2 {
                                }

                                svg {
                                }
                            }

                            .insta {
                                h2 {
                                }

                                svg {
                                }
                            }

                            .twitter {
                                h2 {
                                }

                                svg {
                                }
                            }

                            .linkedln {
                                h2 {
                                }

                                svg {
                                }
                            }
                        }
                    }
                }

                .blockPerimetre {
                    margin: 0px 0 50px;
                    position: unset;
                    width: 100%;
                    .perimetre {
                        flex-direction: row;
                        justify-content: flex-start;
                        flex-wrap: wrap;

                        .block {
                            width: 260px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border: 2px solid #2f2f2f;
                            border-radius: 15px;
                            height: 65px;
                            margin-right: 53px;

                            &:nth-child(0n+4) {
                                margin-right: 0;
                            }
                        }
                    }
                }

                .blockService {
                    .blockFull {
                        .block {
                            width: 33%;
                        }
                    }
                }
            }
        }
    }
}
