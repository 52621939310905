.projet-cree {
    text-align: center;
    h1 {
        width: 90%;
        margin: 50px auto;
    }
    h2 {
        width: 90%;
        margin: 0 auto 50px;
    }
    p {
        width: 90%;
        margin: 0 auto;
        &:last-child {
            margin-bottom: 30px;
        }
    }
}
@media only screen and (min-width: 1280px) {
.projet-cree {
    text-align: center;
    h1 {
        margin-top: 150px;
    }
    h2 {
    }
    p {
        &:last-child {
        }
    }
}
}