.blockChoix{
    .blockEnter{
        .formLink{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            .block{
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                margin-bottom: 20px;
                a{
                    margin-bottom: 15px;
                    line-height: 16px;
                    span{
                        position: relative;
                        left:4px;
                        transition: left 500ms; 
                    }
                    &:hover{
                        span{
                            left: 9px;
                        }
                    }
                }
            }
        }
    }
}
.addLine{
    display: block;
    width: 75px;
    margin: 0 auto 25px;
}
@media only screen and (min-width: 1280px) {
    .blockChoix{
        .blockEnter{
            .formLink{
                margin-top: 25px;
                flex-direction: row;
                align-items: flex-start;
                .block{
                    a{
                        line-height: 26px;
                        span{
                        }
                        &:hover{
                            span{
                            }
                        }
                    }
                }
            }
        }
    }
}