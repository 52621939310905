*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
}
html,body{
    height: 100%;
}
input{
    appearance: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
#up{
    display: none;
}
/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
#app {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

h1{

}
h2{
    font-size: 28px;
    font-weight: 400;
}
a{
    text-decoration: none;
}
button{
    width: 120px;
    height: 35px;
    background-color: #43a1db;
    border-color: #43a1db;
    border-style: none;
    color: white;
    border-radius: 15px;
    cursor: pointer;
}
displaynone{
    display: none !important;
}
select{
    background-color: white;
}
input[type=email]{
    margin-bottom: 0;
    padding-left: 10px;
    border-radius: 15px;
    border: 1px solid #939393;
}
input[type=password]{
    margin-bottom: 0;
    padding-left: 10px;
    border-radius: 15px;
    border: 1px solid #939393;
}
input[type=checkbox]{
    width: 15px!important;
    height: 15px!important;
    margin-bottom: 10px!important;
    padding-left: 10px!important;
    appearance: auto!important;
}
input[type=submit]{
    padding-left: 0;
}
.close{
    cursor: pointer;
}
input{
    padding-left: 5px;
}
@media only screen and (min-width: 1280px) {
    input{
        width: 338px;
        height: 47px;
        border-radius: 15px;
        border: 1px solid #939393;
        padding-left: 10px;
        margin-bottom: 25px;
        font-size: 16px;
    }
}
