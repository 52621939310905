#blockProjetsParti {
    .addProjet {
        margin: 30px auto 0;
        text-align: center;
        a{
            background: #43a1db;
            color: white;
            border-radius: 15px;
            border: none;
            padding: 5px 10px;
            cursor: pointer;
            transition: 500ms;
            width: 240px;
            text-align: center;
            font-size: 12px;
            &:hover{
                background: #3186ba;
            }
        }
    }
    .blockProjets {
        margin: 20px auto;
        font-size: 12px;
        .blockProjetsHeader {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            font-weight: 600;
            .block {
                text-align: left;
                width: 25%;
            }
        }
        .blockProjetsBody {
            display: flex;
            align-items: center;
            height: 120px;
            .block {
                text-align: center;
                width: 25%;
                height: auto;
                position: relative;
                .line.multi{
                    margin-bottom: 20px;
                }
                .blockRelation.multi{
                    margin-bottom: 35px;
                }
                &:nth-child(0n+4) {
                    align-items: flex-start;
                }
                &:nth-child(0n+2){
                    margin-bottom: 10px;
                }
                &:last-child{
                    a{

                        &:last-child{


                        }
                    }
                }
                a{
                    border-radius: 15px;
                    border: none;
                    padding: 5px;
                    cursor: pointer;
                    transition: 500ms;
                    position: relative;
                    width: 40px;
                    &:hover{
                    }
                }
            }
        }
    }
    .modal {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba($color: #000000, $alpha: 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
        .contentModal {
            position: relative;
            background: white;
            padding: 10px 0;
            width: 98vw;
            height: 95vh;
            overflow-y: auto;
            display: flex;
            flex-direction: column;
            .close {
                position: absolute;
                width: 50px;
                right: 0;
                top: 0;
            }
            .close.closePro {
                position: absolute;
                width: 50px;
                right: 0;
                top: 0px;
            }
            .blockRecap {
                text-align: center;
                margin-bottom: 10px;
                p:first-child {
                    font-weight: 600;
                }
            }
            .message {
                margin-bottom: 10px;
            }
            .formulaire {
                margin: 0 auto;
                .tunnel {
                }
            }
        }
    }
    .modalSee {
        .contentModal {
            width: 95vw;
            .close {
            }
            .formulaire {
            }
            .relationBlock{
                text-align: center;
                text-align: center;
                border-bottom: 1px solid black;
                padding: 30px 0;
                &:last-child{
                    border-bottom: none;
                };
                .linePro{
                    .voir{
                        a{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                    img{
                        border-radius: 100%;
                        border: 4px solid #939393;
                    }
                }
                .blockPro{
                    position: relative;
                    .blockRight{
                        position: relative;
                        .action{
                            width: 75%;
                            justify-content: space-between;
                            display: flex;
                            margin: 20px auto;
                            button{
                                &:first-child{
                                    margin-right: 10px;
                                }
                                &:last-child{
                                    margin-left: 10px;
                                    background-color: red;
                                }
                            }
                            .small{
                                width: 100px;
                                &:last-child{
                                    background-color: red;
                                }
                            }
                        }
                        .reponse{
                            margin: 0 auto 20px;
                            width: 75%;
                            button{
                                width: 100%;
                                &:hover {
                                    background: #3186ba;
                                    color: white;
                                }
                            }
                        }
                        .chatBlock{
                            width: 90%;
                            margin: 20px auto;
                            .close{
                                top: 90px;
                            }
                            .chat{
                                border: 1px solid #939393;
                                border-top-right-radius: 15px;
                                border-top-left-radius: 15px;
                                padding: 10px;
                                border-bottom: none;
                                height: 150px;
                                overflow-y: scroll;
                                overscroll-behavior-y: contain;
                                scroll-snap-type: y proximity;
                                .linemessage{
                                    &:last-child{
                                        scroll-snap-align: end;
                                    }
                                }
                                .left{
                                    text-align: left;
                                }
                                .right{
                                    text-align: right;
                                }
                            }
                            .formChat{
                                text-align: center;
                                textarea{
                                    width: 100%;
                                    margin: 0 auto 20px;
                                    border-color: #939393;
                                    border-bottom-left-radius: 15px;
                                    border-bottom-right-radius: 15px;
                                    padding: 10px;
                                    height: unset;
                                    resize: none;
                                    min-height: 55px;
                                }
                                a{
                                    text-decoration: none;
                                    background: #43a1db;
                                    color: white;
                                    border-style: none;
                                    border-radius: 10px;
                                    padding: 10px 20px;
                                    transition: 500ms;
                                    &:hover {
                                        background: #3186ba;
                                        color: white;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .modalDelete {
        align-items: center;
        .contentModal {
            height: 135px;
            text-align: center;
            padding: 15px 0;
            border-radius: 15px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .message {
                margin-bottom: 20px;
            }
            .action {
                display: flex;
                justify-content: space-evenly;
                a {
                    text-decoration: none;
                    background: #43a1db;
                    color: white;
                    border-style: none;
                    border-radius: 10px;
                    padding: 5px 20px;
                    transition: 500ms;
                    display: block;
                    width: 130px;
                    &:last-child{
                        background-color: red;
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 375px) {
    #blockProjetsParti {
        .addProjet {
        }
        .blockProjets {
            .blockProjetsBody {
                .block {
                }
            }
            .blockProjetsHeader {
                .block {
                }
            }
        }
        .modal {
            .contentModal {
                padding: 50px 0 50px;
                width: 95vw;
                .close {
                    position: absolute;
                    width: 50px;
                    right: 0;
                    top: 0;
                }
                .formulaire {
                }
            }
        }
        .modalSee {
            .contentModal {
                position: relative;
                background: white;
                padding: 50px 10px;
                width: 95vw;
                height: 95vh;
                overflow-y: auto;
                .close {
                    position: absolute;
                    width: 50px;
                    right: 0;
                    top: 0;
                }
                .formulaire {
                }
                .relationBlock{
                    .blockPro{
                        .blockRight{
                            .action{
                                .small{
                                    width: 120px;
                                    &:last-child{
                                    }
                                }
                            }
                            .reponse{
                                button{
                                }
                            }
                            .chatBlock{
                                .close{
                                }
                                .chat{
                                    .left{
                                    }
                                    .right{
                                    }
                                }
                                .formChat{
                                    textarea{
                                    }
                                    a{
                                        &:hover {
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .modalDelete {
            .contentModal {
                height: 115px;
                padding: 15px 10px;
                .message {
                }
                .action {
                    a {
                        &:last-child{
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 600px) {
    #blockProjetsParti {
        .addProjet {
            a {
                padding: 10px;
                font-size: 16px;
            }
        }
        .blockProjets {
            margin: 50px auto;
            font-size: 16px;
            .blockProjetsBody {
                .block {
                    &:last-child {
                        .blockRelation {
                            position: relative;
                            width: 100%;
                            height: 20px;
                            margin: 7px 0 4px;
                            a {
                                position: absolute;
                                right: -20px;
                                top: 0;
                                margin: 0;
                                padding: 0;
                            }
                        }
                    }
                    &:nth-child(0n+3) {
                        a {
                            left: unset!important;
                            top: 22px;
                            &:first-child {
                                left: 0;
                            }
                            &:last-child {
                                right: 0;
                                left: unset;
                            }
                        }
                    }
                    a {
                       right: unset!important;
                       top: unset!important;
                    }
                }
            }
            .blockProjetsHeader {
                .block {
                }
            }
        }
        .modal {
            .contentModal {
                .close {
                    cursor: pointer;
                }
                .formulaire {
                    .tunnel {
                    }
                }
            }
        }
        .modalSee {
            .contentModal {
                .formulaire {
                }
                .relationBlock{
                    .linePro{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 90%;
                        margin: 0 auto 30px;
                        .img {
                            width: 150px;
                            height: auto;
                            img{
                                width: 150px;
                                height: auto;
                            }
                        }
                        .voir{

                            a{
                                color: black;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                flex-wrap: wrap;
                                span{
                                    margin-right: 10px;
                                }
                            }
                        }
                    }
                    .blockPro{
                        .blockLeft{
                            display: flex;
                            justify-content: space-between;
                            width: 90%;
                            margin: 0 auto;
                            padding-bottom: 20px;
                            border-bottom: 1px solid #939393;
                        }
                        .blockRight{
                            width: 80%;
                            margin: 40px auto;
                            .action{
                                display: unset;
                                .small{
                                    width: 130px;
                                    &:last-child{
                                    }
                                }
                                .small:disabled{
                                    background-color: #939393;
                                    cursor: unset;
                                }
                            }
                            .reponse{
                                button{
                                }
                            }
                            .chatBlock{
                                .chat{
                                    .left{
                                    }
                                    .right{
                                    }
                                }
                                .formChat{
                                    textarea{
                                    }
                                    a{
                                        &:hover {
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .modalDelete {
            .contentModal {
                height: 105px;
                .message {
                }
                .action {
                    justify-content: center;
                    a {
                        margin: 0 40px;
                        &:last-child{
                            margin-left: 0;
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 800px) {
    #blockProjetsParti {
        .addProjet {
        }
        .blockProjets {
            .blockProjetsBody {
                .block {
                    &:nth-child(0n+2) {
                        a {
                            right: 50px;
                        }
                    }
                    &:nth-child(0n+3) {
                        a {
                            left: unset;
                            right: 50px;
                            &:first-child {
                                left: 15px;
                            }
                            &:last-child {
                                right: 15px;
                            }
                        }

                    }
                }
            }
            .blockProjetsHeader {
                .block {
                }
            }
        }
        .modal {
            .contentModal {
                .close {
                }
                .formulaire {
                    .tunnel {
                    }
                }
            }
        }
        .modalSee {
            .contentModal {
                .formulaire {
                }
                .relationBlock{
                    .linePro{
                        .img{
                        }
                        div{
                        }
                    }
                    .blockPro{
                        .blockLeft{
                            div{
                            }
                        }
                        .blockRight{
                            width: 70%;
                            .action{
                                .small{
                                    width: 160px;
                                    &:last-child{
                                    }
                                }
                            }
                            .reponse{
                                button{
                                }
                            }
                            .chatBlock{
                                .chat{
                                    .left{
                                    }
                                    .right{
                                    }
                                }
                                .formChat{
                                    textarea{
                                    }
                                    a{
                                        &:hover {
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .modalDelete {
            .contentModal {
                padding: 10px;
                .message {
                }
                .action {
                    a {
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 960px) {
    #blockProjetsParti {
        .addProjet {
            text-align: unset;
            margin-left: 0px!important;
        }
        .blockProjets {
            .blockProjetsBody {
                .block {
                    &:last-child {
                        .blockRelation {
                            a {
                            }
                        }
                    }&:nth-child(0n+2) {
                        a {
                            right: 65px;
                        }
                    }
                    &:nth-child(0n+3) {
                        a {
                            left: 55px;
                            &:first-child {
                                left: 20px;
                            }
                            &:last-child {
                                right: 40px;
                            }
                        }
                    }
                    a {
                    }
                }
            }
            .blockProjetsHeader {
                .block {
                }
            }
        }
        .modal {
            .contentModal {
                .close {
                }
                .formulaire {
                    .tunnel {
                        width: 800px;
                        margin: 70px auto 50px;
                    }
                }
            }
        }
        .modalSee {
            .contentModal {
                .close {
                }
                .formulaire {
                }
                .relationBlock{
                    .linePro{
                        .img{
                        }
                        div{
                        }
                    }
                    .blockPro{
                        .blockLeft{
                            div{
                            }
                        }
                        .blockRight{
                            display: flex;
                            flex-wrap: wrap;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            .action{
                                width: auto;
                                margin: 20px 0;
                                .small{
                                    margin-right: 50px;
                                    &:last-child{
                                        margin-right: 20px;
                                    }
                                }
                            }
                            .reponse{
                                margin: 20px 0;
                                width: 42%;
                                button{
                                }
                            }
                            .chatBlock{
                                width: 80%;
                                .close{
                                    top: 70px;
                                }
                                .chat{
                                    .left{
                                    }
                                    .right{
                                    }
                                }
                                .formChat{
                                    textarea{
                                    }
                                    a{
                                        &:hover {
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .modalDelete {
            .contentModal {
                .message {
                }
                .action {
                    a {
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 1280px) {
    #blockProjetsParti {
        margin: auto;
        width: 1200px;
        .addProjet {
        }
        .blockProjets {
            .blockProjetsBody {
                align-items: flex-start;
                margin-bottom: 45px;
                border-top: 1px solid #6c757d;
                padding: 20px 0;
                &:first-child{
                    border: none;
                }
                .block {

                    align-items: center;
                    text-align: left;
                    &:nth-child(0n+2){
                            font-weight: 600;
                        a{
                            right: 0;
                            color:#333 ;
                            svg{
                                position: relative;
                                top: 10px;
                                left: 5px;
                            }
                        }
                    }
                    &:last-child {
                        .blockRelation {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            text-align: center;
                            p {
                                margin-left: 0!important;
                            }
                            a {
                                position: relative!important;
                                right: 30px;
                                margin-left: 10px!important;
                                top: 2px!important;
                            }
                        }
                    }
                    &:nth-child(0n+4) {
                        display: flex!important;
                        align-items: flex-start!important;
                        top: -4px;
                        p {
                            margin-left: 60px;
                            &:first-child{
                                position: relative;
                                top: 5px;
                            }
                        }
                        a{
                            top: -6px!important;
                        }
                    }
                    &:nth-child(0n+3) {
                        div{
                            height: 48px;
                            margin-bottom: 40px;
                        }
                        a {
                            left: 100px;
                            &:first-child {
                                left: 60px;
                            }
                            &:last-child {
                                right: 60px;
                            }
                        }
                    }
                    a {
                        top: 26px;
                        padding: 0;
                    }
                }
            }
            .blockProjetsHeader {
                .block {
                }
            }
        }
        .modal {
            .contentModal {
                padding: 20px 0;
                width: 660px!important;
                &.modalRecap{
                    width: 1100px!important;
                }
                .close {
                }
                .formulaire {
                    flex-wrap: unset;
                    .tunnel {
                        width: 1000px;
                    }
                }
                .blockRecap{
                    p{
                        &:first-child{
                            text-align: right;
                        }
                    }
                }
            }
        }
        .modalSee {
            align-items: center;
            .contentModal {
                .close {
                }
                .formulaire {
                }
                .relationBlock{
                    .linePro{
                        .img{
                        }
                        div{
                        }
                    }
                    .blockPro{
                        .blockLeft{
                            div{
                            }
                        }
                        .blockRight{
                            justify-content: center;
                            .action{
                                .small{
                                    width: 170px;
                                    &:last-child{
                                    }
                                }
                            }
                            .reponse{
                                button{
                                }
                            }
                            .chatBlock{
                                .chat{
                                    .left{
                                        time{
                                            font-size: 11px;
                                            display: block;
                                            height: 15px;
                                        }
                                    }
                                    .right{
                                        time{
                                            font-size: 11px;
                                            display: block;
                                            height: 15px;
                                        }
                                    }
                                }
                                .formChat{
                                    textarea{
                                    }
                                    a{
                                        &:hover {
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .modalSee-1 {
            .contentModal{
                display: flex;
                justify-content: center;
                width: 1000px;
                height: 600px;
            }
        }
        .modalDelete {
            .contentModal {
                height: 170px;
                padding: 10px;
                width: 800px;
                .message {
                }
                .action {
                    a {
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 1920px) {
    #blockProjetsParti {
        .addProjet {
        }
        .blockProjets {
            .blockProjetsBody {
                .block {
                    &:nth-child(0n+2){
                        a{
                        }
                    }
                    &:last-child {
                        .blockRelation {
                            p {
                            }
                            a {
                            }
                        }
                    }
                    &:nth-child(0n+4) {
                        p {
                        }
                    }
                    &:nth-child(0n+3) {
                        a {
                            &:first-child {
                            }
                            &:last-child {
                            }
                        }
                    }
                    a {
                    }
                }
            }
            .blockProjetsHeader {
                .block {
                }
            }
        }
        .modal {
            .contentModal {
                width: 660px!important;
                &.modalRecap{
                    width: 1320px!important;
                }
                .close {
                }
                .formulaire {
                    .tunnel {
                    }
                }
                .blockRecap{
                    p{
                        &:first-child{
                        }
                    }
                }
            }
        }
        .modalSee {
            .contentModal {
                .close {
                }
                .formulaire {
                }
                .relationBlock{
                    .linePro{
                        .img{
                        }
                        div{
                        }
                    }
                    .blockPro{
                        .blockLeft{
                            div{
                            }
                        }
                        .blockRight{
                            .action{
                                .small{
                                    &:last-child{
                                    }
                                }
                            }
                            .reponse{
                                button{
                                }
                            }
                            .chatBlock{
                                .chat{
                                    .left{
                                    }
                                    .right{
                                    }
                                }
                                .formChat{
                                    textarea{
                                    }
                                    a{
                                        &:hover {
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .modalSee-1 {
            .contentModal{
            }
        }
        .modalDelete {
            .contentModal {
                height: 26vh!important;
                .message {
                }
                .action {
                    a {
                    }
                }
            }
        }
    }
}
