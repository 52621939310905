footer{
    height: 80px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px;
    margin-top: auto;
    p{
        color: white;
        a{
            color: white;
            transition: 500ms;
            &:hover{
                color: gray;
            }
        }
    }
}
@media only screen and (min-width: 1280px) {
    footer{
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 100px;
        p{
        }
    }
}
@media only screen and (min-width: 1366px) {
    footer{
        height: 100px;
        p{
        }
    }
}