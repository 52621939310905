#relationProliste{
    .listeRelationBlock{
        .relationHeader{
            .block{
                p{
                }
            }
        }
        .relationLine{
            &:last-child{
            }
            .block{
                a{
                    &:hover{
                    }
                    &:last-child{
                    }
                }
            }
        }
    }
    .modal {
        .contentModal {
            .close {
            }
        }
    }
    .modal.modalDernierMessage{
        .contentModal{
            textarea{
            }
            .dernierMessage{
                button{
                    &:last-child{
                    }
                }
            }
        }
    }
    .modalSee {
        .contentModal {
            padding: unset;
            .header {
                height: 65px;
                width: 100%;
                box-shadow: rgb(0 0 0 / 16%) 0px 2px 1px -1px, rgb(0 0 0 / 6%) 0px 2px 4px -1px;
                .title {
                    margin-bottom: 0;
                    margin-top: 0;
                    position: absolute;
                    bottom: unset;
                    left: 25px;
                    h3 {

                    }
                }
                .close {
                    right: 10px;
                    top: 10px;
                }
            }
            .blockRecape {
                text-align: start;
                display: flex;
                flex-direction: column;
                margin-top: 20px;
                .double {
                    margin-bottom: 50px;
                    h2 {
                    }
                }
                .blockRecap {
                    h3 {
                        font-size: 16px;
                        color: #2F2F2F;
                        font-weight: 600;
                        margin: 0;
                    }
                    p {
                        margin-left: 10px;
                        font-size: 16px;
                        color: #939393;
                        font-weight: 400;
                        margin-left: 10px;
                    }
                }
            }
            .chatBlock{
                width: 90%;
                margin: 20px auto;
                border: 1px solid #939393;
                padding: 10px;
                height: 420px;
                .close{
                    top: -30px;
                }
                .chat{
                    border: none;
                    height: 340px;
                    overflow-y: scroll;
                    overscroll-behavior-y: contain;
                    scroll-snap-type: y proximity;
                    .linemessage{
                        time{
                            font-size: 11px;
                            display: block;
                            height: 15px;
                        }
                        &:last-child{
                            scroll-snap-align: end;
                        }
                        &.left{
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            p{
                                display: block;
                                text-align: left;
                                width: fit-content;
                                max-width: 70%;
                                background: #3186BA;
                                color: white;
                                border: 1px solid #939393;
                                border-radius: 15px;
                                margin-bottom: 10px;
                                margin-top: 10px;
                                padding: 6px;
                            }
                        }
                        &.right{
                            display: flex;
                            flex-direction: column;
                            align-items: flex-end;
                            p{
                                display: block;
                                text-align: right;
                                width: fit-content;
                                max-width: 70%;
                                background: #939393;
                                color: white;
                                border: 1px solid #939393;
                                border-radius: 15px;
                                margin-bottom: 10px;
                                margin-top: 10px;
                                padding: 6px;
                            }
                        }
                    }
                }
                .formChat{
                    position: relative;
                    bottom: 0px;
                    margin-top: 10px;
                    display: flex;
                    justify-content: center;
                    textarea{
                        width: 188px;
                        border-color: #939393;
                        border-right: none;
                        border-bottom-left-radius: 15px;
                        border-top-left-radius: 15px;
                        min-height: 40px;
                        padding-top: 5px;
                        padding-left: 10px;
                        padding-right: 10px;
                        height: unset;
                        resize: none;
                        &::placeholder {
                            font-size: 16px;
                            color: #939393;
                        }
                    }
                    .sendChat {
                        border-top-right-radius: 15px;
                        border-bottom-right-radius: 15px;
                        border: 1px solid #939393;
                        border-left: none;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        a{
                            top: unset!important;
                            text-decoration: none;
                            border-style: none;
                            padding: 10px 20px;
                            transition: 500ms;
                            position: relative;

                        }
                    }
                }
            }
        }
    }
    .modalDelete {
        align-items: center;
        .contentModal {
            height: 135px;
            text-align: center;
            padding: 15px 0;
            border-radius: 15px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .message {
                margin-bottom: 20px;
            }
            .action {
                display: flex;
                justify-content: space-evenly;
                a {
                    text-decoration: none;
                    background: #43a1db;
                    color: white;
                    border-style: none;
                    border-radius: 10px;
                    padding: 5px 20px;
                    transition: 500ms;
                    display: block;
                    width: 130px;
                    &:last-child{
                        background-color: red;
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 375px) {
    #relationProliste{
        .listeRelationBlock{
            .relationHeader{
                .block{
                }
            }
            .relationLine{
                .block{
                    a{
                        &:hover{
                        }
                        &:last-child{
                        }
                    }
                }
            }
        }
        .modal {
            .contentModal {
                .close {
                }
            }
        }
        .modal.modalDernierMessage{
            .contentModal{
                width: 90vw;
                textarea{
                    width: 80vw;
                }
                .dernierMessage{
                    button{
                    }
                    &:last-child{
                    }
                }
            }
        }
        .modalSee {
            .contentModal {
                .close {
                }
                .blockRecape {
                    .blockRecap {
                        p:first-child {
                        }
                    }
                }
                .chatBlock{
                    .right{
                    }
                    .formChat{
                        textarea{
                        }
                        a{
                            &:hover {
                            }
                        }
                    }
                }
            }
        }
        .modalDelete {
            .contentModal {
                height: 115px;
                padding: 15px 10px;
                .message {
                }
                .action {
                    a {
                        &:last-child{
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 600px) {
    #relationProliste{
        .listeRelationBlock{
            .relationHeader{
                .block{
                }
            }
            .relationLine{
                .block{
                    a{
                        &:hover{
                        }
                        &:last-child{
                        }
                    }
                }
            }
        }
        .modal {
            .contentModal {
                padding: unset;
                .close {
                    right: 10px;
                    top: 10px;
                }
            }
        }
        .modal.modalDernierMessage{
            .contentModal{
                width: 80vw;
                padding: unset;
                textarea{
                    width: 50vw;
                    height: 15vh;
                }
                .dernierMessage{
                    button{
                    }
                    &:last-child{
                    }
                }
            }
        }
        .modalSee {
            .contentModal {
                .header {
                    height: 65px;
                    width: 100%;
                    box-shadow: rgb(0 0 0 / 16%) 0px 2px 1px -1px, rgb(0 0 0 / 6%) 0px 2px 4px -1px;
                    .title {
                        margin-bottom: 0;
                        margin-top: 0;
                        position: absolute;
                        bottom: unset;
                        h3 {

                        }
                    }
                    .close {
                    }
                }
                .blockRecape {
                    text-align: start;
                    width: 90%;
                    margin: 0 auto;
                    margin-left: 45px;
                    margin-top: 20px;
                    .double {
                        margin-bottom: 50px;
                        h2 {
                        }
                    }
                    .blockRecap {
                        display: flex;
                        h3 {
                            margin: 0;
                        }
                        p {
                            margin-left: 10px;
                        }
                    }
                }
                .chatBlock{
                    height: 660px;
                    width: 70%;
                    .chat{
                        height: 510px;
                        .linemessage{
                            &:last-child{
                            }
                            &.left{
                                p{
                                    padding: 8px;
                                    max-width: 80%;
                                }
                            }
                            &.right{
                                p{
                                    padding: 8px;
                                    max-width: 80%;
                                }
                            }
                        }
                    }
                    .formChat{
                        margin-top: 25px;
                        textarea{
                            width: 80%;
                            height: 90px;
                            &::placeholder {
                            }
                        }
                        .sendChat {
                            a{
                                top: 23px;
                                svg {
                                    height: 40px;
                                    width: auto;
                                }
                            }
                        }
                    }
                }
            }
        }
        .modalDelete {
            .contentModal {
                .message {
                }
                .action {
                    justify-content: center;
                    a {
                        margin: 0 40px;
                        &:last-child{
                            margin-left: 0;
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 800px) {
    #relationProliste{
        .listeRelationBlock{
            .relationHeader{
                .block{
                }
            }
            .relationLine{
                .block{
                    justify-content: center;
                    a{
                        margin: 0 30px;
                        &:hover{
                        }
                        &:last-child{
                            margin-left: 0;
                        }
                    }
                }
            }
        }
        .modal {
            .contentModal {
                .close {
                }
            }
        }
        .modal.modalDernierMessage{
            .contentModal{
                width: 70vw;
                textarea{
                }
                .dernierMessage{
                    button{
                    }
                    &:last-child{
                    }
                }
            }
        }
        .modalSee {
            .contentModal {
                .header {
                    .title {
                        h3 {
                        }
                        p {
                        }
                    }
                    .close {
                    }
                }
                .blockRecape {
                    text-align: start;
                    margin-bottom: 0;
                    .double {
                        margin-bottom: 50px;
                        h2 {
                        }
                    }
                    .blockRecap {
                        display: flex;
                        h3 {
                            margin: 0;
                        }
                        p {
                            margin-left: 10px;
                        }
                    }
                }
                .chatBlock{
                    .chat{
                        height: 510px;
                        .linemessage{
                            &:last-child{
                            }
                            &.left{
                                p{
                                }
                            }
                            &.right{
                                p{
                                }
                            }
                        }
                    }
                    .formChat{
                        margin-top: 25px;
                        textarea{
                            width: 536px;
                            height: 90px;
                            &::placeholder {
                            }
                        }
                        .sendChat {
                            a{
                                top: 23px;
                                svg {
                                    height: 40px;
                                    width: auto;
                                }
                            }
                        }
                    }
                }
            }
        }
        .modalDelete {
            .contentModal {
                height: 105px;
                padding: 10px;
                .message {
                }
                .action {
                    a {
                        &:last-child{
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 1280px) {
    #relationProliste{
        .listeRelationBlock{
            .relationHeader{
                width: 1200px;
                .block{
                    &.relationTitre{
                        text-align: left;
                        margin-left: 10px;
                    }
                }
            }
            .relationLine{
                .block{
                    height: 30px;
                    a{
                        margin-right: 30px;
                        &:hover{
                        }
                        &:first-child{
                            margin-left: 30px;
                        }
                    }
                    &.relationTitreBlock{
                        justify-content: flex-start;
                        margin-left: 10px;
                    }
                }
            }
        }
        .modal {
            .contentModal {
                padding: unset;
                .close {
                }
            }
        }
        .modal.modalDernierMessage{
            .contentModal{
                width: 40vw;
                textarea{
                    width: 30vw;
                    height: 20vh;
                }
                .dernierMessage{
                    button{
                    }
                    &:last-child{
                    }
                }
            }
        }
        .modalSee {
            .contentModal {
                .header {
                    box-shadow: rgb(0 0 0 / 16%) 0px 2px 1px -1px, rgb(0 0 0 / 6%) 0px 2px 4px -1px;
                    width: 100%;
                    height: 80px;
                    .title {
                        margin-bottom: 0;
                        margin-top: 0;
                        position: relative;
                        bottom: unset;
                        h3{
                            position: absolute;
                            left: 55px;
                            top: 7px;
                        }
                    }
                    .close {
                        right: 10px;
                        top: 15px;
                    }
                }
                .relationBlock {
                    display: flex;
                    padding: unset;
                    .blockRecape {
                        width: 50%;
                        position: relative;
                        margin-top: 45px;
                        .blockRecap {
                            display: flex;
                            p{
                                text-align: left;
                                margin-left: 10px;
                            }
                            .right {
                                p {
                                    &:nth-child(2) {
                                        width: 154px;
                                    }
                                }
                            }
                        }
                    }
                    button{
                        margin: 10px 0 20px;
                    }
                    .chatBlock{
                        width: 50%;
                        background: #F9F9F9;
                        box-shadow: #bdbdbd 1px 0px 3px 0px inset, rgb(255 255 255 / 16%) -3px -3px 6px 1px inset;
                        height: auto;
                        margin: 0px;
                        border: none;
                        padding-bottom: 45px;
                        .close{
                        }
                        .chat{
                            height: 465px;
                            .left{
                                margin-left: 20px;
                            }
                            .right{
                                margin-right: 20px;
                            }
                        }
                        .right{
                        }
                        .formChat{
                            textarea{
                                width: 456px;
                                height: 110px;
                            }
                            .sendChat {
                                width: 80px;
                                a{

                                }
                            }
                        }
                    }
                }
            }
        }
        .modalDelete {
            .contentModal {
                height: 170px;
                padding: 10px;
                width: 800px;
                .message {
                }
                .action {
                    a {
                        &:last-child{
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 1400px) {
    #relationProliste{
        .listeRelationBlock{
            .relationHeader{
                .block{
                }
            }
            .relationLine{
                .block{
                    a{
                        &:hover{
                        }
                        &:first-child{
                        }
                    }
                }
            }
        }
        .modal {
            .contentModal {
                width: 1320px;
                height: unset;
                .close {
                }
            }
        }
        .modal.modalDernierMessage{
            .contentModal{
                textarea{
                }
                .dernierMessage{
                    button{
                    }
                    &:last-child{
                    }
                }
            }
        }
        .modalSee {
            .contentModal {
                .header {
                    .title {
                        h3{

                        }
                    }
                    .close {
                    }
                }
                .relationBlock {
                    .blockRecape {
                        .blockRecap {
                            p{
                            }
                            .right {
                                p {
                                    &:nth-child(2) {
                                    }
                                }
                            }
                        }
                    }
                    button{
                    }
                    .chatBlock{
                        .close{
                        }
                        .chat{
                            .left{
                            }
                            .right{
                            }
                        }
                        .right{
                        }
                        .formChat{
                            textarea{
                            }
                            .sendChat {
                                a{

                                }
                            }
                        }
                    }
                }
            }
        }
        .modalDelete {
            .contentModal {
                .message {
                }
                .action {
                    a {
                        &:last-child{
                        }
                    }
                }
            }
        }
    }
}
