.blockEnter{
    display: flex;
    flex-direction: column;
    width: 95%;
    margin: 70px auto 0;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 14px;
    .formLink{
        text-align: center;
        margin-bottom: 30px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h2{
            margin-bottom: 40px;
            font-size: 20px;
        }
        a{
            width: 184px;
            height: 50px;
            background: #43a1db;
            text-align: center;
            border-radius: 15px;
            color: white;
            font-weight: bold;
            line-height: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            &:hover {
                background: #3186ba;
                color: white
            }
        }
    }
    .formConnexion{
        text-align: center;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        
        a{
            width: 184px;
            height: 50px;
            background: #43a1db;
            text-align: center;
            border-radius: 15px;
            color: white;
            font-weight: bold;
            line-height: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 10px;
            &:hover {
                background: #3186ba;
                color: white
            }
        }
        h2{
            margin-top: 30px;
            margin-bottom: 30px;
            font-size: 20px;
        }
        .row{
            margin-bottom: 10px;
            color: #939393;
            .col-md-6{
                display: flex;
                flex-direction: column;
                align-items: center;
                .form-check{
                    .form-check-input{
                    }
                }
                .form-control{
                    border: solid 1px;
                    border-radius: 15px;
                    border-color: #939393;
                    width: 90%;
                    height: 35px;
                    padding-left: 10px;
                }
                span{
                    color: red;
                }
            }
            .col-md-8{
                display: flex;
                flex-direction: column;
                align-items: center;
                text-decoration: none;
                color: #43a1db;
                button{
                    margin-bottom: 10px;
                }
                .btn.btn-primary{
                    font-size: 10px;
                    &:hover {
                        background: #3186ba;
                    }
                }
                .btn.btn-link{
                    font-size: 10px;
                    color: #43a1db;
                    cursor: pointer;
                    text-decoration: none;
                    background-color: unset;
                    width: unset;
                    display: unset;
                    height: unset;
                    margin-top: 0;
                    font-weight: 400;
                }
            }
        }
        .connexionSocial{
            p{

            }
            .block{
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                width: 150px;
                margin: 0 auto;
                text-align: unset;
                padding-top: 5px;
                a{
                    background: none;
                    line-height: normal;
                    margin-bottom: 0;
                    margin-top: 0;
                    height: unset;
                    font-size: unset;
                    text-align: start;
                }
            }
        }
    }
}
.blockTextCenter{
    margin: 20px auto 30px;
    width: 95%;
    font-size: 12px;
    text-align: center;
}
@media only screen and (min-width: 400px) {
    .blockEnter{
        display: flex;
        flex-direction: column;
        width: 95%;
        margin: 70px auto 0;
        justify-content: space-between;
        align-items: flex-start;
        font-size: 14px;
        .formLink{
            text-align: center;
            margin-bottom: 30px;
            width: 100%;
            h2{
                margin-bottom: 40px;
                font-size: 28px;
            }
            a{
                width: 184px;
                height: 50px;
                background: #43a1db;
                padding: 10px;
                padding-left: 20px;
                padding-right: 20px;
                text-align: center;
                border-radius: 15px;
                color: white;
                font-weight: bold;
                line-height: 25px;
                &:hover {
                    background: #3186ba;
                    color: white
                }
            }
        }
        .formConnexion{
            text-align: center;
            width: 100%;
            height: 100%;
            h2{
                margin-top: 30px;
                margin-bottom: 30px;
                font-size: 28px;
            }
            .row{
                .col-md-6{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .form-check{
                        .form-check-input{
                        }
                    }
                    .form-control{
                        border: solid 1px;
                        border-radius: 15px;
                        border-color: #939393;
                        width: 338px;
                        height: 35px;
                    }
                    span{
                        color: red;
                    }
                }
                .col-md-8{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    text-decoration: none;
                    color: #43a1db;
                    button{
                        margin-bottom: 10px;
                    }
                    .btn.btn-primary{
                        font-size: 10px;
                        &:hover {
                            background: #3186ba;
                        }
                    }
                    .btn.btn-link{
                        font-size: 10px;
                        color: #43a1db;
                        cursor: pointer;
                        text-decoration: none;
                    }
                }
            }
        }
    }
    .blockTextCenter{
        margin: 20px auto 30px;
        width: 95%;
        font-size: 12px;
        text-align: center;
    }
}
@media only screen and (min-width: 1280px) {
    .blockEnter{
        flex-direction: row;
        align-items: flex-start;
        height: 460px;
        margin-top: 130px;
        .formLink{
            align-items: center;
            h2{
                width: 600px;
                margin: 0 auto 100px;
                font-size: 28px;
            }
            a{
                font-size: 28px;
                font-weight: 700;
                width: 358px;
                height: 87px;
                margin-top: 50px;
                &:hover {
                }
            }
        }
        .formConnexion{
            align-items: center;
            a{
                font-size: 28px;
                font-weight: 700;
                width: 358px;
                height: 87px;
                margin-top: 50px;
                margin-bottom: 0;
                &:hover {
                }
            }
            h2{
                width: 600px;
                margin: auto;
                font-size: 28px;
            }
            form{
                margin-top: 20px;
                font-size: 16px;
            }
            .row{
                .col-md-6{
                    .form-check{
                        margin: 10px 0;
                        .form-check-input{
                        }
                    }
                    .form-control{
                        font-size: 16px;
                        padding-left: 10px;
                        height: 47px;
                    }
                }
                .col-md-8{
                    button{
                    }
                    .btn.btn-primary{
                        font-size: 28px;
                        font-weight: 700;
                        width: 358px;
                        height: 87px;
                        &:hover {
                        }
                    }
                    .btn.btn-link{
                        font-size: 16px;
                        background-color: unset;
                        width: unset;
                        display: unset;
                        height: unset;
                        margin-top: 0;
                        font-weight: 400;
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 1400px) {
    .blockEnter{
        height: 520px;
        margin-top: 130px;
        .formLink{
            h2{
            }
            a{
                &:hover {
                }
            }
        }
        .formConnexion{
            h2{
            }
            .row{
                .col-md-6{
                    .form-check{
                        .form-check-input{
                        }
                    }
                    .form-control{
                    }
                }
                .col-md-8{
                    button{
                    }
                    .btn.btn-primary{
                        &:hover {
                        }
                    }
                    .btn.btn-link{
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 1600px) {
    .blockEnter{
        height: 590px;
        margin-top: 130px;
        .formLink{
            h2{
            }
            a{
                &:hover {
                }
            }
        }
        .formConnexion{
            h2{
            }
            .row{
                .col-md-6{
                    .form-check{
                        .form-check-input{
                        }
                    }
                    .form-control{
                    }
                }
                .col-md-8{
                    button{
                    }
                    .btn.btn-primary{
                        &:hover {
                        }
                    }
                    .btn.btn-link{
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 1920px) {
    .blockEnter{
        height: 500px;
        margin-top: 200px;
        .formLink{
            h2{
            }
            a{
                &:hover {
                }
            }
        }
        .formConnexion{
            h2{
            }
            .row{
                .col-md-6{
                    .form-check{
                        .form-check-input{
                        }
                    }
                    .form-control{
                    }
                }
                .col-md-8{
                    button{
                    }
                    .btn.btn-primary{
                        &:hover {
                        }
                    }
                    .btn.btn-link{
                    }
                }
            }
        }
    }
    .blockTextCenter{
        margin: 20px auto 50px;
        width: 1200px;
        font-size: 20px;
    }
}