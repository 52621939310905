.formulaire {
    width: 95%;
    margin: 85px auto 0;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    .tunnel {
        margin: 50px 0;

        ul {
            display: flex;
            justify-content: space-around;
            align-items: center;
            list-style: none;
            li {
                text-decoration: none;
                .blockNb{
                    border: 2px solid #939393;
                    width: 34px;
                    height: 34px;
                    border-radius: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    p{
                        display: block;
                    }
                }
                p{
                    display: none;
                }
                &.selected {
                    .blockNb {
                        border: 2px solid #2f2f2f;
                    }
                }
                &.finish{
                    .blockNb {
                        border: 2px solid #46ce85;
                        background: #46ce85;
                        color: white;
                    }
                }
            }
            .hrline{
                display: none;
                hr{

                }
            }
        }
    }

    .ecran {
        .form {
            margin-bottom: 50px;
            h2 {
                text-align: center;
                margin-bottom: 15px;
            }
            p{
                span{
                    margin-left: 5px;
                }
            }
            .redColor{
                color: red;
            }
            input{
                padding-left: 10px;
                margin: 25px 0 0px;
                height: 35px;
                width: 300px;
                border: 1px solid black;
                border-radius: 15px;
                border-color: #939393;
            }
            .block {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
                flex-wrap: wrap;
                width: 100%;
                margin: 50px 0 0 0;
                select{
                    align-items: center;
                    padding: 10px 25px;
                    width: 300px;
                    text-align: center;
                    margin-bottom: 35px;
                    background: none;
                    border: 2px solid black;
                    border-radius: 10px;
                    cursor: pointer;
                }
                .btn {
                    border: 2px solid black;
                    border-radius: 10px;
                    padding: 10px 25px;
                    width: 90%;
                    text-align: center;
                    margin-bottom: 35px;
                    background: none;
                    transition: 500ms;
                    cursor: pointer;
                    &:hover {
                        border-color: #46ce85;
                    }

                    &.selected {
                        background: #46ce85;
                        border-color: #33ad6c;
                        color: white;
                    }
                }
                &.achat{

                }
            }
            .formblock{
                margin: 50px 0;
                label{
                    font-weight: 600;
                    font-size: 20px;
                }
                input{
                    padding-left: 10px;
                    margin: 25px 0 0px;
                    border-radius: 10px;
                    height: 35px;
                    width: 100%;
                    border: 2px solid black;
                }
                input[type="radio"]{
                    width: unset;
                    margin: 0;
                    width: 13px;
                    height: 13px;
                    appearance: auto;
                }
                textarea{
                    width: 100%;
                    height: 250px;
                    border-radius: 10px;
                    border: 2px solid black;
                    padding: 10px;
                    margin-top: 20px;
                }
                .message{
                    margin: 20px auto;
                    color: red
                }
                .blockNbPiece{
                    width: 220px;
                    height : 100%;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    align-items: center;
                    margin-top: 10px;
                    .nbpiece{
                        margin-right: 25px;
                        border: 2px solid black;
                        flex-wrap: wrap;
                        border-radius: 10px;
                        padding: 5px 10px;
                        margin-top: 10px;
                        transition: 500ms;
                        cursor: pointer;
                        &:hover{
                            border-color: #46ce85;
                            color: white;
                        }
                        &.selected{
                            background: #46ce85;
                            border-color: #33ad6c;
                            color: white;
                        }
                    }
                }
                &.disflex{
                    display: flex;
                    flex-direction: column;
                    flex-wrap: wrap;
                }
                &.displayBlock{
                    display: block;
                }
            }

            .radiobtn{
                display: flex;
                justify-content: space-around;
                align-items: center;
                margin:10px 0 15px;
                .radioBlock{
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    label{
                        margin-right: 10px;
                    }
                }
            }
            .blockRecap{
                margin: 20px 0;
                text-align: center;
                p{
                    font-size: 20px;
                    font-weight: 500;
                    &:first-child{
                        font-weight: 600;
                    }
                }
            }
        }
        .form.colonne{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }


        .addForm {
            display: block;
            text-align: center;
            margin-bottom: 30px;
            span {
                color:black;
            }
        }

        .action {
            margin-bottom: 35px;
            text-align: center;
            a {
                text-decoration: none;
                background: #43a1db;
                color: white;
                margin-left: 35px;
                border-style: none;
                border-radius: 10px;
                padding: 10px 20px;
                transition: 500ms;
                span{
                    position: relative;
                    transition: left 500ms;
                }
                &:first-child{
                    margin-left: 0;
                }
                &:hover {
                    background: #3186ba;
                    color: white;
                    span{
                        left: 10px;
                    }
                }
                &.gray{
                    background:#939393;
                    &:hover{
                        background: #2f2f2f;
                        span{
                            left: -10px;
                        }
                    }
                }
            }
        }
        .typeForm {
            .formbtn {
                margin-left: 0px;
                border: 2px solid black;
                flex-wrap: wrap;
                border-radius: 10px;
                padding: 5px 10px;
                margin-top: 10px;
                transition: 500ms;
                cursor: pointer;
                &:hover{
                    border-color: #46ce85;
                    color: white;
                }
                &.selected{
                    background: #46ce85;
                    border-color: #33ad6c;
                    color: white;
                }
            }
            .btn{
                margin-right: 25px;
            }
        }

        .travauxForm {
            &.blockNbPiece {
                width: 100%!important;

            }
            .formbtn {
                margin-left: 0px;
                width: 100%;
                border: 2px solid black;
                flex-wrap: wrap;
                border-radius: 10px;
                padding: 5px 10px;
                margin-top: 10px;
                transition: 500ms;
                cursor: pointer;
                &:hover{
                    border-color: #46ce85;
                    color: white;
                }
                &.selected{
                    background: #46ce85;
                    border-color: #33ad6c;
                    color: white;
                }
            }
        }
    }
    &.mini{
        .ecran{
            .form{
                .block{
                    margin: 0 auto;
                    .btn.formbtn{

                    }
                    .selectBlock{
                        width: 90%;
                        select{
                            width: 100%;
                        }
                    }
                }
                .double{
                    .formblock.disflex{
                        .blockNbPiece{
                            width: 100%;
                            .nbpiece{
                                margin-right: 17px;
                                margin: 0 auto;
                            }
                        }
                    }
                    input{
                        display: block;
                        width: 90%;
                        margin: 0 auto;
                    }
                }
                input{
                    display: block;
                    width: 90%;
                    margin: 0 auto 15px;
                }
            }
            .createUser{
                span{
                    display: block;
                    margin: 0 auto;
                    text-align: center;
                }
            }
        }
    }
}
@media only screen and (min-width: 414px) {
    .formulaire {
        .tunnel {
            ul {
                li {
                    .blockNb{
                        p{
                        }
                    }
                    p{
                    }
                    &.selected {
                        .blockNb {
                        }
                    }
                    &.finish{
                        .blockNb {
                        }
                    }
                }
                .hrline{
                    display: none;
                    hr{

                    }
                }
            }
        }

        .ecran {
            .form {
                h2 {
                }
                p{
                    span{
                    }
                }
                input{
                }
                .block {
                    select{
                    }
                    .btn {
                        &:hover {
                        }
                        &.selected {
                        }
                    }
                }
                .formblock{
                    label{
                    }
                    input{
                    }
                    input[type="radio"]{
                    }
                    textarea{
                    }
                    .blockNbPiece{
                        width: 100%;
                        .nbpiece{
                            &:hover{
                            }
                            &.selected{
                            }
                        }
                    }
                    &.disflex{
                    }
                }
                .radiobtn{
                    .radioBlock{
                        label{
                        }
                    }
                }
                .blockRecap{
                    p{
                        &:first-child{
                        }
                    }
                }
                input{
                }
            }

            .action {
                a {

                    &:first-child{
                    }
                    &:hover {
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 600px) {
    .formulaire {
        min-height: 636px;
        width: 85%;
        .tunnel {
            ul {
                li {
                    .blockNb{
                        p{
                        }
                    }
                    p{
                    }
                    &.selected {
                        .blockNb {
                        }
                    }
                    &.finish{
                        .blockNb {
                        }
                    }
                }
                .hrline{
                    display: block;
                    width: 50px;
                    height: 5px;
                    hr{
                        border-top: 2px solid  #939393;
                    }
                    &.finish{
                        hr{
                            border-top: 2px solid  #46CE85;
                        }
                    }
                }
            }
        }

        .ecran {
            .form {
                h2 {
                }
                p{
                    span{
                    }
                }
                input{
                }
                .block {
                    select{
                    }
                    .btn {
                        &:hover {
                        }
                        &.selected {
                        }
                    }
                }
                .formblock{
                    label{
                    }
                    input{
                    }
                    input[type="radio"]{
                    }
                    textarea{
                    }
                    .blockNbPiece{
                        .nbpiece{
                            &:hover{
                            }
                            &.selected{
                            }
                        }
                    }
                    &.disflex{
                    }
                }
                .radiobtn{
                    .radioBlock{
                        label{
                        }
                    }
                }
                .blockRecap{
                    p{
                        &:first-child{
                        }
                    }
                }
                input{
                }
            }

            .action {
                a {

                    &:first-child{
                    }
                    &:hover {
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 768px) {
    .formulaire {
        min-height: 700px;
        .tunnel {
            ul {
                li {
                    .blockNb{
                        p{
                        }
                    }
                    p{
                    }
                    &.selected {
                        .blockNb {
                        }
                    }
                    &.finish{
                        .blockNb {
                        }
                    }
                }
                .hrline{
                    hr{
                    }
                    &.finish{
                        hr{
                        }
                    }
                }
            }
        }

        .ecran {
            .form {
                h2 {
                }
                p{
                    span{
                    }
                }
                input{
                }
                .block {
                    select{
                    }
                    .btn {
                        &:hover {
                        }
                        &.selected {
                        }
                    }
                }
                .formblock{
                    label{
                    }
                    input{
                    }
                    input[type="radio"]{
                    }
                    textarea{
                    }
                    .blockNbPiece{
                        .nbpiece{
                            &:hover{
                            }
                            &.selected{
                            }
                        }
                    }
                    &.disflex{
                    }
                }
                .radiobtn{
                    .radioBlock{
                        label{
                        }
                    }
                }
                .blockRecap{
                    p{
                        &:first-child{
                        }
                    }
                }
                input{
                }
            }

            .action {
                a {

                    &:first-child{
                    }
                    &:hover {
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 800px) and (min-height:1280px) {
    .formulaire {
        min-height: 915px;
        width: 90%;
        .tunnel {
            margin: 80px 0;
            ul {
                li {
                    .blockNb{
                        p{
                        }
                    }
                    p{
                    }
                    &.selected {
                        .blockNb {
                        }
                    }
                    &.finish{
                        .blockNb {
                        }
                    }
                }
                .hrline{
                    hr{
                    }
                    &.finish{
                        hr{
                        }
                    }
                }
            }
        }

        .ecran {
            .form {
                h2 {
                }
                p{
                    span{
                    }
                }
                input{
                }
                .block {
                    height: 95%;
                    margin-top: 70px;
                    select{
                        padding: 20px 0;
                        width: 400px;
                        font-size: 16px;
                    }
                    .btn {
                        padding: 25px;
                        &:hover {
                        }
                        &.selected {
                        }
                    }
                }
                .formblock{
                    label{
                    }
                    input{
                        height: 45px;

                    }
                    input[type="radio"]{
                    }
                    textarea{
                    }
                    .blockNbPiece{
                        .nbpiece{
                            padding: 10px 20px;
                            &:hover{
                            }
                            &.selected{
                            }
                        }
                    }
                    &.disflex{
                    }
                }
                .radiobtn{
                    .radioBlock{
                        label{
                        }
                    }
                }
                .blockRecap{
                    p{
                        &:first-child{
                        }
                    }
                }
                input{
                    display: flex;
                    width: 300px;
                    margin: 20px auto;
                }
            }
            .action {
                margin: 50px 0 70px 0;
                a {
                    padding: 20px 45px;
                    margin-left: 50px;
                    &:first-child{
                    }
                    &:hover {
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 960px) {
    .formulaire {
        width: 860px;
        .tunnel {
            ul {
                li {
                    .blockNb{
                        p{
                        }
                    }
                    p{
                    }
                    &.selected {
                        .blockNb {
                        }
                    }
                    &.finish{
                        .blockNb {
                        }
                    }
                }
                .hrline{
                    hr{
                    }
                    &.finish{
                        hr{
                        }
                    }
                }
            }
        }

        .ecran {
            .form {
                h2 {
                }
                p{
                    span{
                    }
                }
                input{
                }
                .block {
                    select{
                    }
                    .btn {
                        &:hover {
                        }
                        &.selected {
                        }
                    }
                }
                .formblock{
                    label{
                    }
                    input{
                    }
                    input[type="radio"]{
                    }
                    textarea{
                    }
                    .blockNbPiece{
                        .nbpiece{
                            &:hover{
                            }
                            &.selected{
                            }
                        }
                    }
                    &.disflex{
                    }
                }
                .radiobtn{
                    .radioBlock{
                        label{
                        }
                    }
                }
                .blockRecap{
                    p{
                        &:first-child{
                        }
                    }
                }
                input{
                    width: 300px;
                    display: flex;
                    margin: 20px auto;
                }
            }

            .action {
                a {

                    &:first-child{
                    }
                    &:hover {
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 1280px) {
    .formulaire {
        width: 1100px;
        margin: 110px auto 0;
        min-height: 0;
        .tunnel {
            ul {
                li {
                    .blockNb{
                        margin: 0 auto 10px;
                        p{
                        }
                    }
                    p{
                        display: block;
                    }
                    &.selected {
                        .blockNb {
                        }
                    }
                    &.finish{
                        .blockNb {
                        }
                    }
                }
                .hrline{
                    position: relative;
                    top: -13px;
                    hr{
                    }
                    &.finish{
                        hr{
                        }
                    }
                }
            }
        }

        .ecran {
            .form {
                h2 {
                }
                p{
                    span{
                    }
                }
                input{
                }
                .block {
                    flex-direction: row;
                    margin: 50px auto 30px;
                    flex-wrap: wrap;
                    width: 90%;
                    justify-content: space-between;
                    .selectBlock{
                        &:nth-child(2n+1){
                            margin-left: 125px;
                        }
                        &:nth-child(2n+2){
                            margin-right: 125px;
                        }
                    }
                    .btn {
                        width: 338px;
                        margin-left: 35px;
                        &:hover {
                        }
                        &.selected {
                        }
                        &:nth-child(2n+1){
                            margin-left: 125px;
                        }
                        &:nth-child(2n+2){
                            margin-right: 125px;
                        }
                    }
                    &.block3{
                        width: 100%;
                        .btn{
                            margin-left: 0;
                            margin-left: 30px;
                            margin-right: 0;
                        }
                    }
                    select{
                        width: 338px;

                    }
                    &.achat {
                        align-items: center;
                        width: 100%;
                        flex-wrap: unset;
                        justify-content: unset;
                        select{
                            width: 250px;
                            margin-bottom: 0px;
                            margin-right: 30px;
                        }
                        .selectBlock{
                            &:nth-child(2n+1){
                                margin-left: 0px;
                            }
                            &:nth-child(2n+2){
                                margin-right: 0px;
                            }
                        }
                    }
                }
                .formblock{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    align-items: center;
                    width: 95%;
                    margin: 0 auto 25px;
                    label{
                        width: 350px;
                    }
                    input{
                        width: 300px;
                        height: 47px;
                        margin: 0 0 0 15px;
                        border-radius: 15px;
                    }

                    input[type="radio"]{
                    }
                    textarea{
                    }
                    .message{
                        margin: 30px 0 0 420px;
                        color: red
                    }
                    .blockNbPiece{
                        margin-left: 125px;
                        .nbpiece{
                            margin-left: 25px;
                            border-radius: 15px;
                            text-align: center;
                            &:hover{
                            }
                            &.selected{
                            }
                        }
                    }
                    &.disflex{
                        flex-direction: row;
                        flex-wrap: unset;
                    }
                    &.terrain{
                        flex-direction: column;
                        align-items: flex-start;
                    }
                    .prixfull{
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        align-items: center;
                        .prixblock{
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            input{
                                margin-left: 15px;
                            }
                        }
                    }
                }
                .radiobtn{
                    margin-left: 50px;
                    width: 50px;
                    .radioBlock{
                        margin-left: 40px;
                        label{
                            width: 50px;
                        }
                    }
                }
                .blockRecap{
                    margin: 0 0 20px;
                    width: 100%;
                    p{
                        display: inline-block;
                        vertical-align: top;
                        width: 48%;
                        text-align: left;
                        margin-left: 10px;
                        &:first-child{
                            text-align: right;
                            margin-right: 10px;
                        }
                    }
                }
                .titre{
                    p{
                        text-align: center !important;
                    }
                }
                input{
                    width: 338px;
                    height: 47px;
                    display: flex;
                    margin: 20px auto;
                    border-color: #939393;
                    border-radius: 15px;
                }
            }
            .recapBlockForm{
                width: 100%;
                h2{
                    margin-bottom: 25px;
                }
                .blockRecap{

                    p{
                        &:first-child{

                        }
                    }
                }
            }
            .action {
                display: flex;
                justify-content: center;
                a {
                    display: block;
                    width: 184px;
                    height: 50px;
                    padding: 15px 20px;
                    border-radius: 15px;
                    &:first-child{
                    }
                    &:hover {
                    }
                }
            }
            .typeForm {
                .formbtn {
                    margin-left: unset;
                    padding: 5px 10px;
                    border-radius: 25px;
                    &:hover{
                    }
                    &.selected{

                    }
                }
                .btn{
                    margin-left: 25px;
                }
            }

            .travauxForm {
                .blockNbPiece {
                    width: unset;
                }
                .formbtn {
                    margin-left: 25px;
                    width: 346px;
                    &:hover{
                    }
                    &.selected{
                    }
                }
                .btn {
                    margin-left: 25px;
                    margin-right: -9px;
                }
            }
        }
        &.mini{
            .ecran{
                .form{
                    .block{
                        align-items: center;
                        width: 70%;
                        .btn.formbtn{
                            width: 230px;
                            margin: 0;
                        }
                        .selectBlock{
                            width: 230px;
                            margin: 0;
                            select{
                                margin-bottom: 0;
                            }
                        }
                        &.full{
                            width: 90%;
                            justify-content: flex-start;
                            .selectBlock{
                                margin-right: 20px;
                                &:last-child{
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                    &.alignDouble{
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-start;
                        width: 94%;
                        margin: 0 auto;
                    }
                    .double{
                        width: 45%;
                        .formblock.disflex{
                            flex-direction: column;
                            .blockNbPiece{
                                margin-left: 0;
                                .nbpiece{
                                }
                            }
                        }
                        input{
                        }
                    }
                    input{
                    }
                    &.budget{
                        display: flex;
                        justify-content: space-evenly;
                        align-items: center;
                        input{
                            width: 445px;
                            margin: 0;
                        }
                    }
                    &.createUser{
                        display: flex;
                        justify-content: space-evenly;
                        align-items: center;
                        flex-wrap: wrap;
                        h2{
                            display: block;
                            width: 100%;
                        }
                        input{
                            width: 445px;
                        }
                    }
                }
                .createUser{
                    span{
                        display: block;
                        margin: 0;
                        text-align: right;
                        width: 92%;
                    }
                }
            }
        }
    }
}
