.navbar{
    width: 100%;
    #lienformhome{
        width: 95%;
        margin: 10px auto 0;
        text-align: center;
        .fontbold{
            font-weight: bold;
        }
    }
    .container{
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
        margin: 0 auto;
        justify-content: space-between;
        align-items: center;
        .navbar-brand{
            position: relative;
            top: 35px;
            img{
                width: 170px;
                height: auto;
            }
        }
        .collapse{
            display: none;
            #navbarSupportContent{

            }
        }
        ul{
            list-style: none;
            width: 100%;
            height: 50px;
            background-color: #2f2f2f;
            text-align: center;
            li{
                margin-top: 18px;
                a{
                    text-decoration: none;
                    color: white;
                    transition: 500ms;
                }
            }
        }
    }
}
@media only screen and (min-width: 800px) {
    .navbar{
        .container{
            height: 200px;
            .navbar-brand{
                img{
                    width: auto;
                    height: 120px;
                }
            }
            .collapse{
            }
            ul{
                height: 80px;
                li{
                    margin-top: 30px;
                    a{
                        font-size: 20px;
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 1280px) {
    .navbar{

        .container{
            width: 90%;
            flex-direction: row;
            height: 80px;
            justify-content: space-between;
            .navbar-brand{
                img{
                    margin-top: 30px;
                }
            }
            .collapse{
                display: flex;
                .navbar-nav{
                    width: 120px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                ul{
                    li{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        a{
                            margin-right: 15px;
                        }
                    }
                }

            }
            ul{
                background-color: unset;
                width: 250px;
                height: 40px;
                li{
                    margin-top: 0;
                    a.pro{
                        font-size: 14px;
                        background-color: #939393;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 250px;
                        height: 40px;
                        border-radius: 20px;
                        &:hover {
                            background: #2f2f2f;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 1920px) {
    .navbar{
        .container{
            .navbar-brand{
                img{
                    height: 140px;
                }
            }
            .collapse{
                .navbar-nav{
                }
                ul{
                    li{
                        a{
                        }
                    }
                }

            }
            ul{
                li{
                    a.pro{
                        &:hover {
                        }
                    }
                }
            }
        }
    }
}
