#consulteventepro {
    .blockSearch {
        padding-top: 50px;
        .bienBlock  {
            padding: 0;
            border: none;
            box-shadow: rgb(0 0 0 / 20%) 0px 3px 8px 1px;
            .headerBien {
                box-shadow: 0 3px 6px 0 #d6d6d6;
                padding-top: 20px;
                height: 65px;
                h2 {
                    font-size: 20px;
                    font-weight: 600;
                    color: #2F2F2F;
                }
            }
            .contentBien {
                .blockRecap {
                    p {
                        font-size: 14px;
                        &:nth-child(2) {
                            color: #939393;
                        }
                    }
                }
            }
            .footerAddBien {
                opacity: 1;
                width: 90%;
                margin-bottom: 45px;
                width: 90%;
                margin: 0 auto;
                background: #ffffff;
                a {
                    border: 2px solid #2F2F2F !important;
                    border-radius: 13px;
                }
            }
        }
    }
}

@media only screen and (min-width: 1280px) {
    #consulteventepro {
    .blockSearch {
        .bienBlock  {
            .headerBien {
                h2 {
                }
            }
            .contentBien {
                .blockRecap {
                    p {
                       
                        }
                    }
                }
            }
            .footerAddBien {
                a {
                    padding: 22px !important;
                    &:hover {
                        border-color: #46CE85 !important;
                    }
                }
            }
        }
    }
}
