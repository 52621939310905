.login{
    .container{
        display: flex;
        justify-content: center;
        align-items: center;
        form{

        }
    }
}

.container{
    .justify-content-center{
        width: 100%;
}
    .card{
        margin-top: 50px;
        text-align: center;
        .card-header{
            text-align: center;
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 20px;
        }
        .card-body{
            .row{
                margin-bottom: 10px;
                color: #939393;
                .col-md-6{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .form-check{
                        .form-check-input{
                        }
                    }
                    .form-control{
                        border: solid 1px;
                        border-radius: 15px;
                        border-color: #939393;
                        width: 90%;
                        height: 35px;
                        padding-left: 10px;
                    }
                    span{
                        color: red;
                    }
                }
                .col-md-8{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    text-decoration: none;
                    color: #43a1db;
                    button{
                        margin-bottom: 10px;
                    }
                    .btn.btn-primary{
                        &:hover {
                            background: #3186ba;
                        }
                    }
                    .btn.btn-link{
                        font-size: 10px;
                        color: #43a1db;
                        cursor: pointer;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 400px) {
    .container{
        .justify-content-center{
            display: flex;
            justify-content: center;
            align-items: center;
    }
        .card{    
            .card-header{
            }
            .card-body{
                .row{
                    .col-md-6{
                        .form-check{
                            .form-check-input{
                            }
                        }
                        .form-control{
                            border: solid 1px;
                            border-radius: 15px;
                            border-color: #939393;
                            width: 338px;
                            height: 35px;
                        }
                        span{
                        }
                    }
                    .col-md-8{
                        button{
                        }
                        .btn.btn-primary{
                            &:hover {
                            }
                        }
                        .btn.btn-link{
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 1280px) {
    .container{
        .justify-content-center{
            display: flex;
            justify-content: center;
            align-items: center;
    }
        .card{
            margin-top: 90px;
            .card-header{
                text-align: center;
                font-size: 28px;
                font-weight: 600;
            }
            .card-body{
                .row{
                    .col-md-6{
                        .form-check{
                            margin: 20px 0;
                            .form-check-input{
                            }
                        }
                        .form-control{
                            font-size: 16px;
                            padding-left: 10px;
                            height: 47px;
                        }
                    }
                    .col-md-8{
                        button{
                        }
                        .btn.btn-primary{
                            font-size: 28px;
                            font-weight: 700;
                            width: 358px;
                            height: 87px;
                            &:hover {
                            }
                        }
                        .btn.btn-link{
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}