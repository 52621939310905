.abonnement {
    .py-4 {
        h2{
            margin-top: 35px;
            text-align: center;
        }
        .blockcontent {
            table {
                table-layout: fixed;
                width: 100%;
                border-collapse: collapse;
                border: 1px solid #000;
                tr {
                    th,
                    td {
                        border: 1px solid #000;
                        padding: 10px;
                    }
                }
            }
        }
        form {
            width: 95%;
            margin: 0 auto;
            #selectionDepartement {
                .from-control {
                    h2 {
                        margin-top: 0;
                        margin-bottom: 15px;
                    }
                    select {
                        border: 2px solid black;
                        border-radius: 10px;
                        padding: 10px 25px;
                        width: 100%;
                        text-align: center;
                        margin-bottom: 25px;
                        background: none;
                        transition: 500ms;
                        cursor: pointer;
                    }
                    a {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        svg {
                        }
                        span {
                            margin-left: 10px;
                        }
                    }
                }
                .listeDepartementSelect {
                    margin-top: 25px;
                    h2 {
                        text-align: center;
                        margin-bottom: 15px;
                    }
                    .listeDepartementHeader {
                        display: flex;
                        p {
                            font-size: 14px;
                            text-align: center;
                        }
                        .blockDepart {
                            width: 33%;
                            border: 1px solid black;
                            padding: 10px 0;
                        }
                        .blockPrix {
                            width: 33%;
                            border: 1px solid black;
                            padding: 10px 0;
                        }
                        .blockAction {
                            width: 33%;
                            border: 1px solid black;
                            padding: 10px 0;
                        }
                    }
                    .listeDepartementBody {
                        div {
                            display: flex;
                            a,
                            p {
                                font-size: 14px;
                                text-align: center;
                                width: 100%;
                            }
                            .blockDepart {
                                width: 33%;
                                border: 1px solid black;
                                padding: 10px 0;
                            }
                            .blockPrix {
                                width: 33%;
                                border: 1px solid black;
                                padding: 10px 0;
                            }
                            .blockAction {
                                width: 33%;
                                border: 1px solid black;
                                padding: 10px 0;
                            }
                        }
                    }
                    .listeDepartementFooter {
                        display: flex;
                        p {
                            font-size: 14px;
                            text-align: center;
                        }
                        .blockDepart {
                            width: 33%;
                            border: 1px solid black;
                            padding: 10px 0;
                        }
                        .blockPrix {
                            width: 33%;
                            border: 1px solid black;
                            padding: 10px 0;
                        }
                        .blockAction {
                            width: 33%;
                            padding: 10px 0;
                        }
                    }
                    .openPayment {
                        margin-top: 35px;
                        a {
                            display: block;
                            text-decoration: none;
                            background: #43a1db;
                            color: white;
                            border-style: none;
                            border-radius: 10px;
                            padding: 10px 20px;
                            transition: 500ms;
                            width: 278px;
                            margin: 0 auto;
                            border: 1px solid #43a1db!important;
                            &:hover{
                                background-color: white;
                                color:#43a1db;
                            }
                        }
                    }
                }   
            }
            #paimentBlock{
                margin-top: 25px;
                .form-control{
                    label{
                        display: block;
                        text-align: center;
                        margin-bottom: 15px;
                        width: 100%;
                    }
                    input{
                        width: 100%;
                        border: 1px solid #939393;
                        border-radius: 15px;
                        padding: 15px;
                    }
                }
                #card-element{
                    width: 100%!important;
                    margin: 25px 0;
                }
                #card-errors{
                    display: none;
                    color: white;
                    background: red;
                    text-align: center;
                    border-radius: 15px;
                    padding: 10px;
                    margin-bottom: 20px;
                }
                #card-button{
                    display: block;
                    margin: 25px auto 0;
                    transition: 500ms;
                    border: 1px solid #43a1db!important;
                    &:hover{
                        background-color: white;
                        color:#43a1db;
                    }
                }
            }
            .modal.modalDelete{
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: rgba($color: #000000, $alpha: 0.5);
                display: flex;
                justify-content: center;
                align-items: center;
                .backgroundModal{
                    background: white;
                    width: 80%;
                    padding: 25px;
                    border-radius: 15px;
                    .message{   
                        text-align: center;
                    }
                    .action.blue{
                        margin-top: 35px;
                        a{
                            display: block;
                            width: 100px;
                            margin: 0 auto;
                            transition: 500ms;
                            border: 1px solid #43a1db;
                            background: #43a1db;
                            color: white;
                            text-align: center;
                            padding: 10px;
                            border-radius: 15px;
                            &:hover{
                                background-color: white;
                                color:#43a1db;
                            }
                        }
                    }
                }
            }
        }
        #subcriptionCancel{
            margin-top: 35px;
            .blockfiltre{
                width: 100%;
                .block{
                    width: 90%;
                    margin: 0 auto 20px;
                    label{
                        display: block;
                        margin: 0 auto;
                        width: 260px;
                        padding: 10px;
                        border-radius: 15px;
                        input{
                            margin-right: 10px;
                        }
                    }
                }
            }
            .tableHeader{
                display: none;
                .trHeader{

                }
            }
            .tableBody{
                margin-bottom: 25px;
                width: 95%;
                margin: 0 auto 50px;
                .tableLine{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    align-items: flex-start;
                    margin-bottom: 25px;
                    .trBody{
                        padding: 10px 5px;
                        font-size: 16px;
                        border: 1px solid black;
                        text-align: center;
                        &:nth-child(1){
                            width: 100%;
                        }
                        &:nth-child(2){
                            position: relative;
                            width: 30%;
                            border-top: none;
                            border-right: none;
                        }
                        &:nth-child(3){
                            position: relative;
                            width: 70%;
                            border-top: none;                            
                            span{
                                margin:0 2px;
                            }
                        }
                        &:nth-child(4){
                            width: 100%;
                            border-top: none;
                            display: flex;
                            justify-content: space-evenly;
                            align-items: center;
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 960px) {
    .abonnement {
        .py-4 {
            h2{
            }
            .blockcontent {
                width: 600px;
                margin: 0 auto 50px;
                table {
                    tr {
                        th,
                        td {
                        }
                    }
                }
            }
            form {
                width: 600px;
                margin: 0 auto 50px;
                #selectionDepartement {
                    .from-control {
                        h2 {
                        }
                        select {
                        }
                        a {
                            svg {
                            }
                            span {
                            }
                        }
                    }
                    .listeDepartementSelect {
                        h2 {
                        }
                        .listeDepartementHeader {
                            p {
                            }
                            .blockDepart {
                            }
                            .blockPrix {
                            }
                            .blockAction {
                            }
                        }
                        .listeDepartementBody {
                            div {
                                a,
                                p {
                                }
                                .blockDepart {
                                }
                                .blockPrix {
                                }
                                .blockAction {
                                }
                            }
                        }
                        .listeDepartementFooter {
                            p {
                            }
                            .blockDepart {
                            }
                            .blockPrix {
                            }
                            .blockAction {
                            }
                        }
                        .openPayment {
                            a {
                                &:hover{
                                }
                            }
                        }
                    }   
                }
                #paimentBlock{
                    .form-control{
                        label{
                        }
                        input{
                        }
                    }
                    #card-element{
                    }
                    #card-button{
                        &:hover{
                        }
                    }
                }
                .modal.modalDelete{
                    .backgroundModal{
                        .message{   
                        }
                        .action.blue{
                            a{
                                &:hover{
                                }
                            }
                        }
                    }
                }
            }
            #subcriptionCancel{
                width: 600px;
                margin: 0 auto 50px;
                .blockfiltre{
                    .block{
                        label{
                            input{
                            }
                        }
                    }
                }
                .tableHeader{
                    .trHeader{
    
                    }
                }
                .tableBody{
                    .tableLine{
                        .trBody{
                            &:nth-child(1){
                            }
                            &:nth-child(2){
                            }
                            &:nth-child(3){
                                span{
                                }
                            }
                            &:nth-child(4){
                            }
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 1280px) {
    .abonnement {
        .py-4 {
            h2{
            }
            .blockcontent {
                margin: 80px auto 50px;
                table {
                    tr {
                        th,
                        td {
                        }
                    }
                }
            }
            form {
                width: 1200px;
                #selectionDepartement {
                    .from-control {
                        display: flex;
                        justify-content: space-around;
                        justify-content: center;
                        flex-wrap: wrap;
                        h2 {
                            width: 100%;
                        }
                        select {
                            width: 338px;
                            display: block;
                            margin-bottom: 0;
                        }
                        a {
                            margin-left: 20px;
                            svg {
                            }
                            span {
                            }
                        }
                    }
                    .listeDepartementSelect {
                        width: 900px;
                        margin: 0 auto;
                        h2 {
                        }
                        .listeDepartementHeader {
                            p {
                            }
                            .blockDepart {
                            }
                            .blockPrix {
                            }
                            .blockAction {
                            }
                        }
                        .listeDepartementBody {
                            div {
                                a,
                                p {
                                }
                                .blockDepart {
                                }
                                .blockPrix {
                                }
                                .blockAction {
                                }
                            }
                        }
                        .listeDepartementFooter {
                            p {
                            }
                            .blockDepart {
                            }
                            .blockPrix {
                            }
                            .blockAction {
                            }
                        }
                        .openPayment {
                            a {
                                &:hover{
                                }
                            }
                        }
                    }   
                }
                #paimentBlock{
                    width: 900px;
                    margin: 35px auto 0;
                    .form-control{
                        label{
                        }
                        input{
                        }
                    }
                    #card-element{
                    }
                    #card-button{
                        &:hover{
                        }
                    }
                }
                .modal.modalDelete{
                    .backgroundModal{
                        .message{   
                        }
                        .action.blue{
                            a{
                                &:hover{
                                }
                            }
                        }
                    }
                }
            }
            #subcriptionCancel{
                width: 1200px;
                margin: 25px auto;
                .blockfiltre{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .block{
                        width: auto;
                        label{
                            display: flex;
                            justify-content: space-around;
                            align-items: center;
                            width: 270px;
                            input{
                                width: 14px;
                                height: 14px;
                                margin-bottom: 0;
                            }
                        }
                    }
                }
                .tableHeader{
                    width: 1200px;
                    display: block;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    .trHeader{
                        padding: 10px 5px;
                        font-size: 16px;
                        border: 1px solid black;
                        height: 75px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        &:nth-child(1){
                            width: unset;
                            width: 620px;
                        }
                        &:nth-child(2){
                            width: 150px;
                        }
                        &:nth-child(3){
                            width: 280px;
                            span{
                            }
                        }
                        &:nth-child(4){
                            width: 150px;
                        }
                    }
                }
                .tableBody{
                    width: 1200px;
                    .tableLine{
                        margin-bottom: 0;
                        justify-content: flex-start;
                        .trBody{
                            height: 75px;
                            display: flex;
                            align-items: center;
                            &:nth-child(1){
                                border-top: none;
                                width: unset;
                                width: 620px;
                                text-align: left;
                            }
                            &:nth-child(2){
                                border-top: none;
                                width: 150px;
                                justify-content: center;
                            }
                            &:nth-child(3){
                                border-top: none;
                                width: 280px;
                                justify-content: center;
                                span{
                                }
                            }
                            &:nth-child(4){
                                border-top: none;
                                width: 150px;
                            }
                        }
                    }
                }
            }
        }
    }
}
