#consulteventepro {
    .blockAlert {
        .alertList {
            padding: 0;
            .listeAlert {
                margin: 0;
                border-radius: none;
                .header {
                    height: 60px;
                    width: 100%;
                    box-shadow: 0 3px 6px 0 #d6d6d6;
                    .title {
                        position: absolute;
                        left: 20px;
                        top: 15px;
                        h2 {
                            font-size: 20px;
                            font-weight: 600;
                            color: #2F2F2F;
                        }
                    }
                    .close {
                        top: 6px;
                        right: 13px;
                    }
                }
                .filter {
                    position: relative;
                    margin-top: 25px;
                    margin-left: 20px;
                    margin-bottom: 35px;
                    label {
                        font-size: 16px;
                    }
                    .MuiAutocomplete-root {
                        width: 250px;
                        position: relative;
                        .MuiFormControl-root {
                            margin-top: 10px;
                            .MuiOutlinedInput-root {
                                border-radius: 15px;
                                border-color: #939393;
                                height: 38px;
                                padding-right: 35px;
                                box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
                            }
                            .MuiAutocomplete-input {
                                height: 4px;
                                background: url("../img/Search.svg") right center no-repeat;
                            }
                            .MuiOutlinedInput-notchedOutline {

                            }
                        }
                    }
                }
                .alertLine {
                    box-shadow: rgb(0 0 0 / 23%) 0px 3px 12px -2px, rgb(0 0 0 / 5%) 0px 4px 6px -2px;
                    width: 90%;
                    margin: 0 auto 20px;
                    border-radius: 15px;
                    padding: 10px;
                    position: relative;
                    .headerAlertBlock {
                        .left {
                            p {
                                font-size: 16px;
                                color: #2F2F2F;
                                a {
                                    position: relative;
                                    left: 10px;
                                    top: 3px;
                                }
                            }
                        }
                        .right {
                            text-align: end;
                            margin-top: 25px;
                            a {
                                background: #707070;
                                color: white;
                                padding: 6px 15px;
                                border-radius: 15px;
                                font-size: 14px;
                                box-shadow: rgb(0 0 0 / 20%) 0px 3px 12px 0px;
                                position: absolute;
                                left: 5px;
                                top: 38px;
                            }
                            p {
                                font-size: 14px;
                                color: #2F2F2F;
                            }
                        }
                    }
                    .emailBlock {
                        p {
                            font-size: 14px;
                            color: #939393;
                            margin-bottom: 15px;
                            margin-top: 10px;
                        }
                    }
                    .type {
                        font-size: 14px;
                        color: #939393;
                    }
                    .localisation {
                        font-size: 14px;
                        color: #939393;
                    }
                    .surface {
                        font-size: 14px;
                        color: #939393;
                    }
                    .budget {
                        font-size: 14px;
                        color: #939393;
                    }
                    .piece {
                        font-size: 14px;
                        color: #939393;
                    }
                    .delais {
                        font-size: 14px;
                        color: #939393;
                    }
                    P {
                        font-size: 14px;
                        color: #939393;      
                    }
                    .action {
                       text-align: end;
                       a{
                           
                       }
                    }
                }
            }
        }
    }
}

#consulteventepro {
    .blockAlert {
        .createAlert {
            height: 440px;
            top: 55%;
            .alertSubmitForm {
                .header {
                    height: 80px;
                    width: 100%;
                    box-shadow: 0 3px 6px 0 #d6d6d6;
                    position: relative;
                    bottom: 59px;
                    .title {
                        position: absolute;
                        top: 30px;
                        left: 3px;
                        p{
                            font-size: 20px;
                            font-weight: 600;
                            color: #2F2F2F;
                        }
                    }
                    .close {
                        top: -3px;
                    }
                }
                .body {
                    position: relative;
                    bottom: 26px;
                    width: 90%;
                    margin: 0 auto;
                    input {
                        margin: 13px auto;
                        height: 45px;
                        border: none;
                        box-shadow: rgb(0 0 0 / 18%) 0px 3px 12px -2px, rgb(0 0 0 / 5%) 0px 4px 6px -3px;
                    }
                    a {
                        border: 2px solid #2f2f2f;
                        padding: 22px 10px;
                        color: #2f2f2f;
                        background: #ffffff;
                        border-radius: 15px;
                        transition: 200ms;
                        font-size: 16px;
                        display: block;
                        width: 200px;
                        transition: 500ms;
                        &:hover {
                            transition: 500ms;
                            background: #6FCB8B;
                            border-color: #5AAA72;
                            color: white;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 375px) {
    #consulteventepro {
        .blockAlert {
            .createAlert {
                .alertSubmitForm {
                    .header {
                        .title {
                            left: 5%;
                            p{
                            }
                        }
                        .close {
                        }
                    }
                    .body {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        input {
                            width: 300px;
                        }
                        a {
                            &:hover {
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 600px) {
    #consulteventepro {
        .blockAlert {
            .alertList {
                .listeAlert {
                    .header {
                        .title {
                            h2 {
                            }
                        }
                        .close {
                     
                        }
                    }
                    .filter {
                        width: 514px;
                        margin: 0 auto;
                        padding-top: 25px;
                        padding-bottom: 35px;
                        svg {
                            position: absolute;
                            top: 64px;
                            left: 485px;
                        }
                        label {
                        }
                        .MuiAutocomplete-root {
                            width: 514px;
                            .MuiFormControl-root {

                                .MuiOutlinedInput-root {
                                }
                                .MuiAutocomplete-input {
                                    height: 8px;
                                }
                                .MuiOutlinedInput-notchedOutline {
    
                                }
                            }
                        }
                    }
                    .alertLine {
                        width: 514px;
                        padding: 15px;
                        .headerAlertBlock {
                            .left {
                                p {
                                    a {
                                    }
                                }
                            }
                            .right {
                                margin-top: 5px;
                                a {
                                    right: 15px;
                                    top: 15px;
                                    left: unset;
                                }
                                p {
                                }
                            }
                        }
                        .emailBlock {
                            p {
                             
                            }
                        }
                        .type {
                         
                        }
                        .localisation {
                           
                        }
                        .surface {
                          
                        }
                        .budget {
                           
                        }
                        .piece {
                          
                        }
                        .delais {
                          
                        }
                        P {
                        }
                        .action {
                           a{
                               
                           }
                        }
                    }
                }
            }
        }
    }
    #consulteventepro {
        .blockAlert {
            .alertList.createAlert {
                height: 468px !important;
                top: 42% !important;
                .alertSubmitForm {
                    .header {
                        .title {
                            p{
                            }
                        }
                        .close {
                        }
                    }
                    .body {
                        input {
                        }
                        a {
                            &:hover {
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 800px) {
    #consulteventepro {
        .blockAlert {
            .alertList {
                width: 660px;
                height: 960px;
                left: 54%;
                margin-left: -43%;
                .listeAlert {
                    .header {
                        .title {
                            left: 50px;
                            h2 {
                            }
                        }
                        .close {
                     
                        }
                    }
                    .filter {
                        width: 550px;
                        svg {
                            top: 68px;
                            left: 519px;
                        }
                        label {
                        }
                        .MuiAutocomplete-root {
                            width: 550px;
                            .MuiFormControl-root {

                                .MuiOutlinedInput-root {
                                    height: 45px;
                                    width: 550px;
                                }
                                .MuiAutocomplete-input {
                                    height: 9px;
                                }
                                .MuiOutlinedInput-notchedOutline {
    
                                }
                            }
                        }
                    }
                    .alertLine {
                        width: 550px;
                        .headerAlertBlock {
                            .left {
                                p {
                                    a {
                                    }
                                }
                            }
                            .right {
                                a {
                                }
                                p {
                                }
                            }
                        }
                        .emailBlock {
                            p {
                             
                            }
                        }
                        .type {
                         
                        }
                        .localisation {
                           
                        }
                        .surface {
                          
                        }
                        .budget {
                           
                        }
                        .piece {
                          
                        }
                        .delais {
                          
                        }
                        P {
                        }
                        .action {
                           a{
                               
                           }
                        }
                    }
                }
            }
        }
    }
    #consulteventepro {
        .blockAlert {
            .alertList.createAlert {
                margin-left: -48% !important;
                height: 558px !important;
                width: 660px !important;
                top: 25% !important;
                .alertSubmitForm {
                    .header {
                        bottom: 90px;
                        .title {
                            p{
                            }
                        }
                        .close {
                            top: 15px;
                            right: 20px
                        }
                    }
                    .body {
                        bottom: 15px;
                        input {
                            width: 550px;
                        }
                        }
                        a {
                            margin-top: 65px;
                            width: 338px;
                            text-align: center;
                            &:hover {
                            }
                        }
                }
            }
        }
    }
}

@media only screen and (min-width: 1280px) {
    #consulteventepro {
        .blockAlert {
            .alertList {
                width: 660px;
                left: 54%;
                margin-left: -43%;
                .listeAlert {
                    .header {
                        .title {
                            left: 50px;
                            top: 28px;
                            h2 {
                            }
                        }
                        .close {
                            top: 20px;
                            right: 20px;
                        }
                    }
                    .filter {
                        width: 550px;
                        svg {
                            top: 68px;
                            left: 519px;
                        }
                        label {
                        }
                        .MuiAutocomplete-root {
                            width: 550px;
                            .MuiFormControl-root {

                                .MuiOutlinedInput-root {
                                    height: 45px;
                                    width: 550px;
                                }
                                .MuiAutocomplete-input {
                                    height: 9px;
                                }
                                .MuiOutlinedInput-notchedOutline {
    
                                }
                            }
                        }
                    }
                    .alertLine {
                        width: 550px;
                        .headerAlertBlock {
                            .left {
                                p {
                                    a {
                                    }
                                }
                            }
                            .right {
                                a {
                                }
                                p {
                                }
                            }
                        }
                        .emailBlock {
                            p {
                             
                            }
                        }
                        .type {
                         
                        }
                        .localisation {
                           
                        }
                        .surface {
                          
                        }
                        .budget {
                           
                        }
                        .piece {
                          
                        }
                        .delais {
                          
                        }
                        P {
                        }
                        .action {
                           a{
                               
                           }
                        }
                    }
                }
            }
        }
    }
    #consulteventepro {
        .blockAlert {
            .alertList.createAlert {
                margin-left: -48% !important;
                height: 558px !important;
                width: 660px !important;
                top: 43% !important;
                padding: 0 !important;
                .alertSubmitForm {
                    .header {
                        .title {
                            p{
                            }
                        }
                        .close {
                        }
                    }
                    .body {
                        input {
                        }
                        }
                        a {
                            &:hover {
                            }
                        }
                }
            }
        }
    }
}

@media only screen and (min-width: 1600px) {
    #consulteventepro {
        .blockAlert {
            .alertList {
                margin-left: -41% !important;
                padding: 0 !important;
                height: 750px !important;
                .alertSubmitForm {
                    .header {
                        .title {
                            p{
                            }
                        }
                        .close {
                        }
                    }
                    .body {
                        input {
                        }
                        }
                        a {
                            &:hover {
                            }
                        }
                }
            }
        }
    }

    #consulteventepro {
        .blockAlert {
            .createAlert {
                margin-left: -48% !important;
                height: 558px !important;
                width: 660px !important;
                top: 43% !important;
                padding: 0 !important;
                .alertSubmitForm {
                    .header {
                        .title {
                            p{
                            }
                        }
                        .close {
                        }
                    }
                    .body {
                        input {
                        }
                        }
                        a {
                            &:hover {
                            }
                        }
                }
            }
        }
    }
}

@media only screen and (min-width: 1920px) {
    #consulteventepro {
        .blockAlert {
            .alertList {
                height: 885px !important;
                left: 54%;
                top: 50%;
                margin-left: -43%;
                .listeAlert {
                    .header {
                        .title {
                            left: 50px;
                            h2 {
                            }
                        }
                        .close {
                     
                        }
                    }
                    .filter {
                        width: 550px;
                        svg {
                            top: 68px;
                            left: 519px;
                        }
                        label {
                        }
                        .MuiAutocomplete-root {
                            width: 550px;
                            .MuiFormControl-root {

                                .MuiOutlinedInput-root {
                                    height: 45px;
                                    width: 550px;
                                }
                                .MuiAutocomplete-input {
                                    height: 9px;
                                }
                                .MuiOutlinedInput-notchedOutline {
    
                                }
                            }
                        }
                    }
                    .alertLine {
                        width: 550px;
                        .headerAlertBlock {
                            .left {
                                p {
                                    a {
                                    }
                                }
                            }
                            .right {
                                a {
                                }
                                p {
                                }
                            }
                        }
                        .emailBlock {
                            p {
                             
                            }
                        }
                        .type {
                         
                        }
                        .localisation {
                           
                        }
                        .surface {
                          
                        }
                        .budget {
                           
                        }
                        .piece {
                          
                        }
                        .delais {
                          
                        }
                        P {
                        }
                        .action {
                           a{
                               
                           }
                        }
                    }
                }
            }
        }
    }
    #consulteventepro {
        .blockAlert {
            .createAlert {
                left: 81% !important;
                .alertSubmitForm {
                    .header {
                        .title {
                            p{
                            }
                        }
                        .close {
                        }
                    }
                    .body {
                        input {
                        }
                        }
                        a {
                            &:hover {
                            }
                        }
                }
            }
        }
    }
}