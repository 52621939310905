#blockProjetsParti {
    .addProjet {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        a{
            margin-bottom: 10px;
            cursor: pointer;
            &:hover{
            }
        }
    }
    .blockProjets {
        .blockProjetsHeader {
            .block {
            }
        }
        .blockProjetsBody {
            .block {
                &:nth-child(0n+4) {
                    text-align: center;
                    font-weight: 600;
                    display: block;
                    p{
                        margin-left: 0;
                    }
                    a{
                        color:#43a1db ;
                        svg{
                            position: relative;
                            top: 9px;
                            left: 5px;
                        }
                    }
                }
                &:last-child{
                    a{
                        &:last-child{
                        }
                    }
                }
                a{
                    &:hover{
                    }
                }
            }
        }
    }
    .modalRecap {
        .modalRecap {
            .close {
            }
            .close.closePro {
            }
            .containerRecape {
                padding-top: 25px;
                box-shadow: rgb(204 219 232) 0px 3px 3px -1px inset, rgb(255 255 255 / 50%) -3px -3px 6px 1px inset;
                .blockRecap {
                    text-align: center;
                    margin-bottom: 15px;
                    p:first-child {
                        font-weight: 600;
                    }
                    h4 {
                        font-size: 16px;
                        color: #2F2F2F;
                        font-weight: 600;
                        margin-bottom: 10px;
                    }
                    p {
                        font-size: 16px;
                        color: #939393;
                        font-weight: 400;
                    }
                }
            }
        }
    }
    .modalSee {
        .firstMessage{
            display: none;
        }
        .contentModal {
            width: 95vw;
            .header {
                height: 80px;
                .title {
                    margin-bottom: 25px;
                    margin-top: 25px;
                    h3 {
                        text-align: start;
                        margin-left: 30px;
                    }
                    p {
                        font-size: 20px;
                        font-weight: 600;
                        margin-left: 10px;
                    }
                }
                .close {
                }
            }
            .containerRecape {
                padding-top: 25px;
                box-shadow: rgb(204 219 232) 0px 3px 3px -1px inset, rgb(255 255 255 / 50%) -3px -3px 6px 1px inset;
                .blockRecap {
                    text-align: center;
                    margin-bottom: 15px;
                    p:first-child {
                        font-weight: 600;
                    }
                    h4 {
                        font-size: 16px;
                        color: #2F2F2F;
                        font-weight: 600;
                        margin-bottom: 10px;
                    }
                    p {
                        font-size: 16px;
                        color: #939393;
                        font-weight: 400;
                    }
                }
            }
            .formulaire {
            }
            .relationBlock{
                box-shadow: rgb(0 0 0 / 16%) 0px 0px 5px 0px, rgb(0 0 0 / 10%) 0px 0px 1px 0px;
                text-align: center;
                text-align: center;
                border-bottom: 1px solid black;
                padding-top: 30px;
                &:last-child{
                    border-bottom: none;
                };
                .blockLeft {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .linePro{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        img{
                            width: 100%;
                            height: auto;
                            border-radius: 100%;
                            border: 4px solid #939393;
                        }
                        .nom {
                            font-size: 28px;
                            margin-top: 25px;
                        }
                        .voir{
                            a{
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                margin-top: 8px;
                                margin-bottom: 50px;
                                color: #2F2F2F;
                                span {
                                    font-size: 16px;
                                    margin-right: 10px;
                                }
                            }
                        }
                    }
                    .blockPro {
                        .status {
                            display: flex;
                            h3 {
                                font-size: 16px;
                                margin: unset;
                                width: 142px;
                                text-align: end;
                            }
                            p {
                                font-size: 16px;
                                color:#939393;
                                margin-left: 20px;
                                position: relative;
                                top: 19px;
                            }
                        }
                        .fonction {
                            display: flex;
                            margin-top: 20px;
                            h3 {
                                font-size: 16px;
                                margin: unset;
                                width: 142px;
                                text-align: end;
                            }
                            p {
                                font-size: 16px;
                                color:#939393;
                                margin-left: 20px;
                            }
                        }
                        .agence {
                            display: flex;
                            margin-top: 20px;
                            h3 {
                                font-size: 16px;
                                margin: unset;
                                width: 142px;
                                text-align: end;
                            }
                            p {
                                font-size: 16px;
                                color:#939393;
                                margin-left: 20px;
                            }
                        }
                        .message {
                            display: flex;
                            margin-top: 20px;
                            h3 {
                                font-size: 16px;
                                margin: unset;
                                width: 141px;
                                text-align: end;
                            }
                            p {
                                font-size: 16px;
                                color:#939393;
                                margin-left: 20px;
                                margin-bottom: 50px;
                            }
                        }
                        .action {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            button {
                                border: 2px solid black;
                                border-radius: 15px;
                                color: #2F2F2F;
                                font-size: 16px;
                                padding: 2px 25px;
                                height: 68px;
                                width: 255px;
                                text-align: center;
                                margin-bottom: 15px;
                                background: none;
                                transition: 500ms;
                                cursor: pointer;
                                &:disabled{
                                    background: #6FCB8B;
                                    border-color: #5AAA72;
                                    color: #FFFFFF;
                                    cursor:not-allowed ;
                                }
                                &:nth-child(1){
                                    &:hover{
                                        transition: 550ms;
                                        border-color: #6FCB8B;
                                    }
                                }
                                &:nth-child(2){
                                    &:hover{
                                        transition: 550ms;
                                        border-color: #939393;
                                    }
                                    &.selected{
                                        background: #939393;
                                        border-color: #2F2F2F;
                                        color: #FFFFFF;
                                    }
                                }
                            }
                        }
                    }
                }
                .blockRight{
                    position: relative;
                    padding-top: 25px;
                        .reponse{
                            margin: 0 auto 20px;
                            width: 75%;
                            button{
                                width: 100%;
                                &:hover {
                                    background: #3186ba;
                                    color: white;
                                }
                            }
                        }
                        .chatBlock{
                            width: 90%;
                            margin: 20px auto;
                            border: 1px solid #939393;
                            padding: 10px;
                            height: 420px;
                            .close{
                                top: 90px;
                            }
                            .chat{
                                height: 340px;
                                overflow-y: scroll;
                                overscroll-behavior-y: contain;
                                scroll-snap-type: y proximity;
                                .linemessage{
                                    &:last-child{
                                        scroll-snap-align: end;
                                    }
                                    &.left{
                                        display: flex;
                                        flex-direction: column;
                                        align-items: flex-start;
                                        time{
                                            font-size: 11px;
                                            display: block;
                                            height: 15px;
                                        }
                                        p{
                                            display: block;
                                            text-align: left;
                                            width: fit-content;
                                            max-width: 70%;
                                            background: #3186BA;
                                            color: white;
                                            border: 1px solid #939393;
                                            border-radius: 15px;
                                            margin-bottom: 10px;
                                            margin-top: 10px;
                                            padding: 6px;
                                        }
                                    }
                                    &.right{
                                        display: flex;
                                        flex-direction: column;
                                        align-items: flex-end;
                                        time{
                                            font-size: 11px;
                                            display: block;
                                            height: 15px;
                                        }
                                        p{
                                            display: block;
                                            text-align: right;
                                            width: fit-content;
                                            max-width: 70%;
                                            background: #939393;
                                            color: white;
                                            border: 1px solid #939393;
                                            border-radius: 15px;
                                            margin-bottom: 10px;
                                            margin-top: 10px;
                                            padding: 6px;
                                        }
                                    }
                                }
                            }
                            .formChat{
                                position: relative;
                                bottom: 0px;
                                margin-top: 10px;
                                display: flex;
                                justify-content: center;
                                textarea{
                                    width: 188px;
                                    border-color: #939393;
                                    border-right: none;
                                    border-bottom-left-radius: 15px;
                                    border-top-left-radius: 15px;
                                    min-height: 40px;
                                    padding-top: 5px;
                                    padding-left: 10px;
                                    padding-right: 10px;
                                    height: unset;
                                    resize: none;
                                    &::placeholder {
                                        font-size: 16px;
                                        color: #939393;
                                    }
                                }
                                .sendChat {
                                    border-top-right-radius: 15px;
                                    border-bottom-right-radius: 15px;
                                    border: 1px solid #939393;
                                    border-left: none;
                                    a{
                                        text-decoration: none;
                                        border-style: none;
                                        padding: 10px 20px;
                                        transition: 500ms;
                                        position: relative;
                                        top: 10px;
                                    }
                                }
                            }
                        }
                }

            }
        }
    }
}
@media only screen and (min-width: 375px) {
    #blockProjetsParti {
        .addProjet {
        }
        .blockProjets {
            .blockProjetsBody {
                .block {
                }
            }
            .blockProjetsHeader {
                .block {
                }
            }
        }
        .modal {
            .contentModal {
                padding: 50px 0 50px;
                width: 95vw;
                .formulaire {
                }
            }
        }
        .modalSee {
            .contentModal {
                .formulaire {
                }
                .relationBlock{
                    .blockPro{
                        .blockRight{
                            .action{
                                .small{
                                    &:last-child{
                                    }
                                }
                            }
                            .reponse{
                                button{
                                }
                            }
                            .chatBlock{
                                .close{
                                }
                                .chat{
                                    .left{
                                    }
                                    .right{
                                    }
                                }
                                .formChat{
                                    textarea{
                                    }
                                    a{
                                        &:hover {
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 600px) {
    #blockProjetsParti {
        .addProjet {
            a {
                font-size: 16px;
                padding: 15px 50px;
            }
        }
        .blockProjets {
            margin: 50px auto;
            font-size: 16px;
            .blockProjetsBody {
                .block {
                    &:last-child {
                        .blockRelation {
                            position: relative;
                            width: 100%;
                            height: 20px;
                            margin: 7px 0 4px;
                            a {
                                position: absolute;
                                right: -20px;
                                top: -3px;
                                margin: 0;
                                padding: 0;
                            }
                        }
                    }
                    &:nth-child(0n+3) {
                        a {
                            left: 34px;
                            top: 22px;
                            &:first-child {
                                left: 0;
                            }
                            &:last-child {
                                right: 0;
                                left: unset;
                            }
                        }
                    }
                    a {
                        right: 30px;
                        top: 50px;
                    }
                }
            }
            .blockProjetsHeader {
                .block {
                }
            }
        }
        .modal {
            .contentModal {
                .header {
                    .title {
                        margin-bottom: 40px;
                    }
                }
                .close {
                    cursor: pointer;
                }
                .formulaire {
                    .tunnel {
                    }
                }
            }
        }
        .modalSee {
            .contentModal {
                .header {
                    .title {
                        p {
                        }
                    }
                    .close {
                    }
                }
                .formulaire {
                }
                .relationBlock{
                    &:last-child{
                    };
                    .blockLeft {
                        margin-bottom: 50px;
                        .linePro{
                            img{
                            }
                            .nom {
                            }
                            .voir{
                                a{
                                    span {
                                    }
                                }
                            }
                        }
                        .blockPro {
                            .status {
                                h3 {
                                }
                                p {
                                }
                            }
                            .fonction {
                                h3 {
                                }
                                p {
                                }
                            }
                            .agence {
                                h3 {
                                }
                                p {
                                }
                            }
                            .message {
                                h3 {
                                }
                                p {
                                }
                            }
                            .action {
                                button {
                                    width: 338px;
                                    &:nth-child(1){
                                        &:hover{
                                        }
                                        &:disabled{
                                            background: #6FCB8B;
                                            border-color: #5AAA72;
                                            color: #FFFFFF;
                                        }
                                    }
                                    &:nth-child(2){
                                        &:hover{
                                        }
                                        &.selected{
                                            background: #939393;
                                            border-color: #2F2F2F;
                                            color: #FFFFFF;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .blockRight{
                        .chatBlock{
                            height: 660px;
                            width: 70%;
                            .close{
                                top: 90px;
                            }
                            .chat{
                                height: 510px;
                                .linemessage{
                                    &:last-child{
                                    }
                                    &.left{
                                        p{
                                            padding: 8px;
                                            max-width: 80%;
                                        }
                                    }
                                    &.right{
                                        p{
                                            padding: 8px;
                                            max-width: 80%;
                                        }
                                    }
                                }
                            }
                            .formChat{
                                margin-top: 25px;
                                textarea{
                                    width: 80%;
                                    height: 90px;
                                    &::placeholder {
                                    }
                                }
                                .sendChat {
                                    a{
                                        top: 23px;
                                        &:hover {
                                        }
                                        svg {
                                            height: 40px;
                                            width: auto;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 800px) {
    #blockProjetsParti {
        .addProjet {
        }
        .blockProjets {
            .blockProjetsBody {
                .block {
                    &:nth-child(0n+2) {
                        a {
                            right: 50px;
                        }
                    }
                    &:nth-child(0n+3) {
                        a {
                            left: unset;
                            right: 50px;
                            &:first-child {
                                left: 15px;
                            }
                            &:last-child {
                                right: 15px;
                            }
                        }

                    }
                }
            }
            .blockProjetsHeader {
                .block {
                }
            }
        }
        .modal {
            .contentModal {
                .header {
                    .title {
                    }
                }
                .close {
                    cursor: pointer;
                }
                .containerRecape {
                    width: 74%;
                    margin: 0 auto;
                    .blockRecap {
                        display: flex;
                        text-align: start;
                        p:first-child {
                        }
                        h4 {
                            display: block;
                            width: 200px;
                        }
                        p {
                            margin-left: 10px;
                        }
                    }
                }
                .formulaire {
                    .tunnel {
                    }
                }
            }
        }
        .modalSee {
            .contentModal {
                .header {
                    .title {
                        p {
                        }
                    }
                    .close {
                    }
                }
                .formulaire {
                }
                .relationBlock{
                    &:last-child{
                    };
                    .blockLeft {
                        .linePro{
                            img{
                            }
                            .nom {
                            }
                            .voir{
                                a{
                                    span {
                                    }
                                }
                            }
                        }
                        .blockPro {
                            .status {
                                h3 {
                                }
                                p {
                                }
                            }
                            .fonction {
                                h3 {
                                }
                                p {
                                }
                            }
                            .agence {
                                h3 {
                                }
                                p {
                                }
                            }
                            .message {
                                h3 {
                                }
                                p {
                                }
                            }
                            .action {
                                button {
                                    &:nth-child(1){
                                        &:hover{
                                        }
                                        &:disabled{
                                            background: #6FCB8B;
                                            border-color: #5AAA72;
                                            color: #FFFFFF;
                                        }
                                    }
                                    &:nth-child(2){
                                        &:hover{
                                        }
                                        &.selected{
                                            background: #939393;
                                            border-color: #2F2F2F;
                                            color: #FFFFFF;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .blockRight{
                        .chatBlock{
                            .close{
                            }
                            .chat{
                                .linemessage{
                                    &:last-child{
                                    }
                                    &.left{
                                        p{
                                        }
                                    }
                                    &.right{
                                        p{
                                        }
                                    }
                                }
                            }
                            .formChat{
                                margin-top: 25px;
                                textarea{
                                    width: 536px;
                                    height: 90px;
                                    &::placeholder {
                                    }
                                }
                                .sendChat {
                                    a{
                                        top: 23px;
                                        &:hover {
                                        }
                                        svg {
                                            height: 40px;
                                            width: auto;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 960px) {
    #blockProjetsParti {
        .addProjet {
            text-align: unset;
            margin-left: 40px;
        }
        .blockProjets {
            .blockProjetsBody {
                .block {
                    &:last-child {
                        .blockRelation {
                            a {
                            }
                        }
                    }&:nth-child(0n+2) {
                        a {
                            right: 65px;
                        }
                    }
                    &:nth-child(0n+3) {
                        a {
                            left: 55px;
                            &:first-child {
                                left: 20px;
                            }
                            &:last-child {
                                right: 40px;
                            }
                        }
                    }
                    a {
                    }
                }
            }
            .blockProjetsHeader {
                .block {
                }
            }
        }
        .modal {
            .contentModal {
                .header {
                    .title {
                    }
                }
                .close {
                    cursor: pointer;
                }
                .containerRecape {
                    box-shadow: none;
                    .blockRecap {
                        p:first-child {
                        }
                        h4 {
                        }
                        p {
                            margin-left: 10px;
                        }
                    }
                }
                .formulaire {
                    .tunnel {
                    }
                }
            }
        }
        .modalSee {
            .contentModal {
                .header {
                    .title {
                        p {
                        }
                    }
                    .close {
                    }
                    .containerRecape {
                        box-shadow: unset;
                        .blockRecap {
                            p:first-child {
                            }
                            h4 {
                            }
                            p {
                            }
                        }
                    }
                }
                .formulaire {
                }
                .relationBlock{
                    &:last-child{
                    };
                    .blockLeft {
                        .linePro{
                            img{
                            }
                            .nom {
                            }
                            .voir{
                                a{
                                    span {
                                    }
                                }
                            }
                        }
                        .blockPro {
                            .status {
                                h3 {
                                }
                                p {
                                }
                            }
                            .fonction {
                                h3 {
                                }
                                p {
                                }
                            }
                            .agence {
                                h3 {
                                }
                                p {
                                }
                            }
                            .message {
                                h3 {
                                }
                                p {
                                }
                            }
                            .action {
                                button {
                                    &:nth-child(1){
                                        &:hover{
                                        }
                                        &:disabled{
                                            background: #6FCB8B;
                                            border-color: #5AAA72;
                                            color: #FFFFFF;
                                        }
                                    }
                                    &:nth-child(2){
                                        &:hover{
                                        }
                                        &.selected{
                                            background: #939393;
                                            border-color: #2F2F2F;
                                            color: #FFFFFF;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .blockRight{
                        .chatBlock{
                            height: 735px;
                            width: 665px;
                            .close{
                            }
                            .chat{
                                height: 540px;
                                .linemessage{
                                    &:last-child{
                                    }
                                    &.left{
                                        p{
                                        }
                                    }
                                    &.right{
                                        p{
                                        }
                                    }
                                }
                            }
                            .formChat{
                                margin-top: 25px;
                                textarea{
                                    width: 451px;
                                    height: 110px;
                                    padding: 15px;
                                    &::placeholder {
                                    }
                                }
                                .sendChat {
                                    width: 85px;
                                    a{
                                        top: 33px;
                                        &:hover {
                                        }
                                        svg {
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .modalDelete {
            .contentModal {
                .message {
                }
                .action {
                    a {
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 1280px) {
    #blockProjetsParti {
        margin: auto;
        width: 1200px;
        .addProjet {
            flex-direction: row;
            width: 100%;
            justify-content: space-evenly;
            a{
                width: 500px;
                &:nth-child(2){
                    margin-left: 10px;
                }
            }
        }
        .blockProjets {
            .blockProjetsBody {
                .block {
                    &:nth-child(0n+2){
                        a{
                            right: 0;
                        }
                    }
                    &:last-child {
                        .blockRelation {
                            text-align: start;
                            p {
                                margin-left: 50px;
                            }
                            a {
                                right: 30px;
                            }
                        }
                    }
                    &:nth-child(0n+4) {
                        align-items: center;
                        p {
                            margin-left: 0px;
                        }
                    }
                    &:nth-child(0n+3) {
                        a {
                            left: 100px;
                            &:first-child {
                                left: 60px;
                            }
                            &:last-child {
                                right: 60px;
                            }
                        }
                    }
                    a {
                        top: 26px;
                        padding: 0;
                    }
                }
            }
            .blockProjetsHeader {
                font-size: 20px;
                .block {
                }
            }
        }
        .modal {
            .contentModal {
                .header {
                    box-shadow: rgb(0 0 0 / 16%) 0px 1px 1px -1px, rgb(0 0 0 / 6%) 0px 2px 4px -1px;
                    .title {
                    }
                }
                .close {
                    cursor: pointer;
                }
                .formulaire {
                    .tunnel {
                    }
                }
            }
        }
        .modalSee {
            .contentModal {
                padding: unset;
                .header {
                    .title {
                        margin-bottom: 40px;
                        margin-top: 25px;
                        margin-left: 45px;
                        p {
                        }
                    }
                    .close {
                        top: 13px;
                    }
                }
                .formulaire {
                }
                .relationBlock{
                    display: flex;
                    padding: unset;
                    &:last-child{
                    };
                    .blockLeft {
                        width: 50%;
                        position: relative;
                        top: 20px;
                        padding-top: 35px;
                        padding-bottom: 28px;
                        .linePro{
                            img{
                            }
                            .nom {
                            }
                            .voir{
                                a{
                                    span {
                                    }
                                }
                            }
                        }
                        .blockPro {
                            .status {
                                h3 {
                                    width: 160px;
                                    text-align: end;
                                }
                                p {
                                    .status {
                                        display: flex;
                                        h3 {
                                            width: 160px;
                                            text-align: end;
                                        }
                                        p {
                                        }
                                    }
                                    .fonction {
                                        display: flex;
                                        margin-top: 20px;
                                        h3 {
                                            width: 158px;
                                            text-align: end;
                                        }
                                        p {

                                        }
                                    }
                                    .agence {
                                        h3 {
                                            width: 158px;
                                            text-align: end;
                                        }
                                        p {
                                        }
                                    }
                                    .message {
                                        h3 {
                                            width: 157px;
                                            text-align: end;
                                        }
                                        p {
                                        }
                                    }
                                }
                            }
                            .fonction {
                                display: flex;
                                margin-top: 20px;
                                h3 {
                                    font-size: 16px;
                                    margin: unset;
                                    width: 158px;
                                    text-align: end;
                                }
                                p {
                                    font-size: 16px;
                                    color:#939393;
                                    margin-left: 20px;
                                }
                            }
                            .agence {
                                display: flex;
                                margin-top: 20px;
                                h3 {
                                    font-size: 16px;
                                    margin: unset;
                                    width: 158px;
                                    text-align: end;
                                }
                                p {
                                    font-size: 16px;
                                    color:#939393;
                                    margin-left: 20px;
                                }
                            }
                            .message {
                                display: flex;
                                margin-top: 20px;
                                h3 {
                                    font-size: 16px;
                                    margin: unset;
                                    width: 157px;
                                    text-align: end;
                                }
                                p {
                                    font-size: 16px;
                                    color:#939393;
                                    margin-left: 20px;
                                    margin-bottom: 50px;
                                }
                            }
                            .action {
                                margin-top: 25px;
                                button {
                                    margin-top: 5px;
                                    &:nth-child(1){
                                        &:hover{
                                        }
                                        &:disabled{
                                            background: #6FCB8B;
                                            border-color: #5AAA72;
                                            color: #FFFFFF;
                                        }
                                    }
                                    &:nth-child(2){
                                        &:hover{
                                        }
                                        &.selected{
                                            background: #939393;
                                            border-color: #2F2F2F;
                                            color: #FFFFFF;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .blockRight{
                        width: 50%;
                        padding-top: 35px;
                        background: #F9F9F9;
                        box-shadow: #bdbdbd 1px 0px 3px 0px inset, rgb(255 255 255 / 16%) -3px -3px 6px 1px inset;
                        .chatBlock{
                            height: auto;
                            width: 100%;
                            margin: 0px;
                            border: none;
                            .close{
                            }
                            .chat{
                                height: 580px;
                                .linemessage{
                                    &:last-child{
                                    }
                                    &.left{
                                        p{
                                            margin-left: 25px;
                                        }
                                    }
                                    &.right{
                                        p{
                                            margin-right: 25px;
                                        }
                                    }
                                }
                            }
                            .formChat{

                                textarea{
                                    &::placeholder {
                                    }
                                }
                                .sendChat {
                                    width: 85px;
                                    a{
                                        top: 33px;
                                        &:hover {
                                        }
                                        svg {
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .modalSee-1 {
            .contentModal{
                display: flex;
                justify-content: center;
                width: 1000px;
                height: 600px;
            }
        }
        .modalDelete {
            .contentModal {
                height: 170px;
                padding: 10px;
                width: 800px;
                .message {
                }
                .action {
                    a {
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 1440px) {
    #blockProjetsParti {
        .addProjet {
        }
        .blockProjets {
            .blockProjetsBody {
                .block {
                    &:nth-child(0n+2){
                        a{
                        }
                    }
                    &:last-child {
                        .blockRelation {
                            p {
                            }
                            a {
                            }
                        }
                    }
                    &:nth-child(0n+4) {
                        p {
                        }
                    }
                    &:nth-child(0n+3) {
                        a {
                            &:first-child {
                            }
                            &:last-child {
                            }
                        }
                    }
                    a {
                    }
                }
            }
            .blockProjetsHeader {
                .block {
                }
            }
        }
        .modal {
            .contentModal {
                width: 1320px;
                .header {
                    .title {
                    }
                }
                .close {
                    cursor: pointer;
                }
                .formulaire {
                    .tunnel {
                    }
                }
            }
        }
        .modalSee {
            .contentModal {
                .header {
                    .title {
                        p {
                        }
                    }
                    .close {
                        top: 13px;
                    }
                }
                .formulaire {
                }
                .relationBlock{
                    &:last-child{
                    };
                    .blockLeft {
                        .linePro{
                            img{
                            }
                            .nom {
                            }
                            .voir{
                                a{
                                    span {
                                    }
                                }
                            }
                        }
                        .blockPro {
                            .status {
                                h3 {
                                }
                                p {
                                }
                            }
                            .fonction {
                                h3 {
                                }
                                p {
                                }
                            }
                            .agence {
                                h3 {
                                }
                                p {
                                }
                            }
                            .message {
                                h3 {
                                }
                                p {
                                }
                            }
                            .action {
                                button {
                                    &:nth-child(1){
                                        &:hover{
                                        }
                                        &:disabled{
                                        }
                                    }
                                    &:nth-child(2){
                                        &:hover{
                                        }
                                        &.selected{
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .blockRight{
                        .chatBlock{
                            height: auto;
                            margin: 0 auto;
                            .close{
                            }
                            .chat{
                                .linemessage{
                                    &:last-child{
                                    }
                                    &.left{
                                        p{
                                        }
                                    }
                                    &.right{
                                        p{
                                        }
                                    }
                                }
                            }
                            .formChat{

                                textarea{
                                    &::placeholder {
                                    }
                                }
                                .sendChat {
                                    a{
                                        &:hover {
                                        }
                                        svg {
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .modalSee-1 {
            .contentModal{
            }
        }
        .modalDelete {
            .contentModal {
                .message {
                }
                .action {
                    a {
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 1920px) {
    #blockProjetsParti {
        .addProjet {
        }
        .blockProjets {
            .blockProjetsBody {
                .block {
                    &:nth-child(0n+2){
                        a{
                        }
                    }
                    &:last-child {
                        .blockRelation {
                            p {
                            }
                            a {
                            }
                        }
                    }
                    &:nth-child(0n+4) {
                        p {
                        }
                    }
                    &:nth-child(0n+3) {
                        a {
                            &:first-child {
                            }
                            &:last-child {
                            }
                        }
                    }
                    a {
                    }
                }
            }
            .blockProjetsHeader {
                .block {
                }
            }
        }
        .modal {

            .contentModal {
                height: 86vh;
                .header {
                    .title {
                    }
                }
                .close {
                    cursor: pointer;
                }
                .formulaire {
                    .tunnel {
                    }
                }
            }
        }
        .modalSee {
            .contentModal {
                .header {
                    .title {
                        p {
                        }
                    }
                    .close {
                        top: 13px;
                    }
                }
                .formulaire {
                }
                .relationBlock{
                    &:last-child{
                    };
                    .blockLeft {
                        .linePro{
                            img{
                            }
                            .nom {
                            }
                            .voir{
                                a{
                                    span {
                                    }
                                }
                            }
                        }
                        .blockPro {
                            .status {
                                h3 {
                                }
                                p {
                                }
                            }
                            .fonction {
                                h3 {
                                }
                                p {
                                }
                            }
                            .agence {
                                h3 {
                                }
                                p {
                                }
                            }
                            .message {
                                h3 {
                                }
                                p {
                                }
                            }
                            .action {
                                button {
                                    &:nth-child(1){
                                        &:hover{
                                        }
                                        &:disabled{
                                        }
                                    }
                                    &:nth-child(2){
                                        &:hover{
                                        }
                                        &.selected{
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .blockRight{
                        .chatBlock{
                            height: auto;
                            width: 655px;
                            margin: 0 auto;
                            .close{
                            }
                            .chat{
                                .linemessage{
                                    &:last-child{
                                    }
                                    &.left{
                                        p{
                                        }
                                    }
                                    &.right{
                                        p{
                                        }
                                    }
                                }
                            }
                            .formChat{

                                textarea{
                                    &::placeholder {
                                    }
                                }
                                .sendChat {
                                    a{
                                        &:hover {
                                        }
                                        svg {
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .modalSee-1 {
            .contentModal{
            }
        }
        .modalDelete {
            .contentModal {
                .message {
                }
                .action {
                    a {
                    }
                }
            }
        }
    }
}

