.page404{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h1{
        margin-bottom: 20px;
        font-size: 28px;
        text-align: center;
    }
    h2{
        margin-bottom: 20px;
        font-size: 22px;
    }
    p{
        a{
            text-decoration: none;
            background: #43a1db;
            color: white;
            margin: 0 10px;
            border-style: none;
            border-radius: 10px;
            padding: 10px;
            transition: 500ms;
            display: block;
            width: 105px;
            height: 40px;
            &:hover{
                background: #3186ba;
            }
        }
    }
}