#formcontactinde{
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba($color: #000000, $alpha: 0.5);
    justify-content: center;
    align-items: center;
    form{
        position: relative;
        width: 90%;
        height: 50%;
        background: white;
        padding: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        .close{
            position: absolute;
            top: -10px;
            right: -10px;
            width: 30px;
            height: 30px;
            border-radius: 100%;
            background: black;
            display: flex;
            justify-content: center;
            align-items: center;
            p{
                color: white;
                font-weight: 900;
            }
        }
        .block{
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;
            flex-wrap: wrap;
            input{
                padding-left: 10px;
                border-radius: 15px;
                border: 1px solid #939393;
                margin-bottom: 15px;
                height: 35px;
            }
        }
        input[type="submit"]{
            width: 184px;
            height: 50px;
            background: #43a1db;
            border: none;
            text-align: center;
            border-radius: 15px;
            color: white;
            font-weight: bold;
            line-height: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
    }
}
@media only screen and (min-width: 1280px) {
    #formcontactinde{
        form{
            width: 70%;
            height: 50%;
            flex-direction: row;
            .close{
                p{
                }
            }
            .block{
                width: 100%;
                flex-direction: row;
                input{
                }
            }
            input[type="submit"]{

            }
        }
    }
}
@media only screen and (min-width: 1600px) {
    #formcontactinde{
        form{
            width: 60%;
            .close{
                p{
                }
            }
            .block{
                input{
                }
            }
            input[type="submit"]{

            }
        }
    }
}
@media only screen and (min-width: 1920px) {
    #formcontactinde{
        form{
            width: 45%;
            height: 40%;
            .close{
                p{
                }
            }
            .block{
                input{
                }
            }
            input[type="submit"]{

            }
        }
    }
}