#relationProliste{
    .listeRelationBlock{
        text-align: center;
        .relationHeader{
            display: flex;
            .block{
                width: 35%;
                font-weight: 600;
                padding-bottom: 25px;
                p{
                    font-weight: 600;
                }
            }
        }
        .relationLine{
            display: flex;
            border-bottom: 1px solid #939393;
            &:last-child{
                border-bottom: none;
            }
            .block{
                width: 35%;
                flex-wrap: wrap;
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                margin-bottom: 15px;
                padding-top: 15px;
                a{
                    &:hover{
                    }
                    &:last-child{
                    }
                }
            }
        }
    }
    .modal {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba($color: #000000, $alpha: 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
        .contentModal {
            position: relative;
            background: white;
            padding: 50px 0 50px;
            width: 95vw;
            .close {
                position: absolute;
                width: 50px;
                right: 0;
                top: 0;
            }
        }
    }
    .modal.modalDernierMessage{
        .contentModal{
            border-radius: 15px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 50px 0 30px;
            textarea{
                border: 1px solid #939393;
                width: 85vw;
                height: 30vh;
                border-radius: 15px;
                margin-bottom: 20px;
            }
            .dernierMessage{
                display: flex;
                button{
                    margin-right: 10px;
                    &:last-child{
                        margin-right: 0;
                        margin-left: 10px;
                    }
                }
            }
        }
    }
    .modalSee {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba($color: #000000, $alpha: 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
        .contentModal {
            padding: 50px 10px;
            width: 95vw;
            height: 95vh;
            overflow: auto;
            .close {
                position: absolute;
                width: 50px;
                right: 0;
                top: 0;
            }
            .blockRecape {
                text-align: center;
                .blockRecap {
                    text-align: center;
                    margin-bottom: 10px;
                    p:first-child {
                        font-weight: 600;
                    }
                }
            }
        }
    }
    .modalDelete {
        align-items: center;
        .contentModal {
            height: 135px;
            text-align: center;
            padding: 15px 0;
            border-radius: 15px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .message {
                margin-bottom: 20px;
            }
            .action {
                display: flex;
                justify-content: space-evenly;
                a {
                    text-decoration: none;
                    background: #43a1db;
                    color: white;
                    border-style: none;
                    border-radius: 10px;
                    padding: 5px 20px;
                    transition: 500ms;
                    display: block;
                    width: 130px;
                    &:last-child{
                        background-color: red;
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 375px) {
    #relationProliste{
        .listeRelationBlock{
            .relationHeader{
                .block{
                }
            }
            .relationLine{
                .block{
                    a{
                        &:hover{
                        }
                        &:last-child{
                        }
                    }
                }
            }
        }
        .modal {
            .contentModal {
                .close {
                }
            }
        }
        .modal.modalDernierMessage{
            .contentModal{
                width: 90vw;
                textarea{
                    width: 80vw;
                }
                .dernierMessage{
                    button{
                    }
                    &:last-child{
                    }
                }
            }
        }
        .modalSee {
            .contentModal {
                .close {
                }
                .blockRecape {
                    .blockRecap {
                        p:first-child {
                        }
                    }
                }
                .chatBlock{
                    height: 660px;
                    width: 70%;
                    .right{
                    }
                    .formChat{
                        textarea{
                        }
                        a{
                        }
                    }
                }
            }
        }
        .modalDelete {
            .contentModal {
                height: 115px;
                padding: 15px 10px;
                .message {
                }
                .action {
                    a {
                        &:last-child{
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 600px) {
    #relationProliste{
        .listeRelationBlock{
            .relationHeader{
                .block{
                }
            }
            .relationLine{
                .block{
                    a{
                        &:hover{
                        }
                        &:last-child{
                        }
                    }
                }
            }
        }
        .modal {
            .contentModal {
                .close {
                }
            }
        }
        .modal.modalDernierMessage{
            .contentModal{
                width: 80vw;
                textarea{
                    width: 50vw;
                    height: 15vh;
                }
                .dernierMessage{
                    button{
                    }
                    &:last-child{
                    }
                }
            }
        }
        .modalSee {
            .contentModal {
                .close {
                }
                .blockRecape {
                    .blockRecap {
                        p:first-child {
                        }
                    }
                }
                .chatBlock{
                    .right{
                    }
                    .formChat{
                        textarea{
                        }
                        a{
                            &:hover {
                            }
                        }
                    }
                }
            }
        }
        .modalDelete {
            .contentModal {
                .message {
                }
                .action {
                    justify-content: center;
                    a {
                        margin: 0 40px;
                        &:last-child{
                            margin-left: 0;
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 800px) {
    #relationProliste{
        .listeRelationBlock{
            .relationHeader{
                .block{
                }
            }
            .relationLine{
                .block{
                    justify-content: center;
                    a{
                        margin: 0 30px;
                        &:hover{
                        }
                        &:last-child{
                            margin-left: 0;
                        }
                    }
                }
            }
        }
        .modal {
            .contentModal {
                .close {
                }
            }
        }
        .modal.modalDernierMessage{
            .contentModal{
                width: 70vw;
                textarea{
                }
                .dernierMessage{
                    button{
                    }
                    &:last-child{
                    }
                }
            }
        }
        .modalSee {
            .contentModal {
                .close {
                }
                .blockRecape {
                    .blockRecap {
                        p:first-child {
                        }
                    }
                }
                .chatBlock{
                    .right{
                    }
                    .formChat{
                        textarea{
                        }
                        a{
                            &:hover {
                            }
                        }
                    }
                }
            }
        }
        .modalDelete {
            .contentModal {
                height: 105px;
                padding: 10px;
                .message {
                }
                .action {
                    a {
                        &:last-child{
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 1280px) {
    #relationProliste{
        .listeRelationBlock{
            .relationHeader{
                width: 1200px;
                .block{
                    text-align: left;
                }
            }
            .relationLine{
                .block{
                    height: 50px!important;
                    text-align: left;
                    justify-content: flex-start!important;
                    a{
                        margin-right: 30px;
                        &:hover{
                        }
                        &:first-child{
                            margin-left: 0!important;
                        }
                    }
                }
            }
        }
        .modal {
            .contentModal {
                .close {
                }
            }
        }
        .modal.modalDernierMessage{
            .contentModal{
                width: 40vw;
                textarea{
                    width: 30vw;
                    height: 20vh;
                }
                .dernierMessage{
                    button{
                    }
                    &:last-child{
                    }
                }
            }
        }
        .modalSee {
            .contentModal {
                .close {
                }
                button{
                    margin: 10px 0 20px;
                }
                .chatBlock{
                    width: 700px;
                    .close{
                    }
                    .chat{
                        width: 100%;
                        .left{
                        }
                        .right{
                        }
                    }
                    .right{
                    }
                    .formChat{
                        textarea{
                            width: 100%;
                        }
                        a{
                            &:hover {
                            }
                        }
                    }
                }
            }
        }
        .modalDelete {
            .contentModal {
                height: 170px;
                padding: 10px;
                width: 800px;
                .message {
                }
                .action {
                    a {
                        &:last-child{
                        }
                    }
                }
            }
        }
    }
}
